import Logo from "app/assets/img/logo-dark-small.png";
import { Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import SideNav from "./side-nav";

const SideBar = (): JSX.Element => {
  return (
    <aside className="w-80 h-screen shrink-0 hidden md:flex bg-black tracking-wide flex-col">
      <Box className=" h-20">
        <div className="h-full">
          <Link to="/">
            <img src={Logo} className="h-full px-6 py-5 invert" />
          </Link>
        </div>
      </Box>
      <SideNav />
    </aside>
  );
};

export default SideBar;
