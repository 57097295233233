import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IMeta,
  IRefundData,
  IThirdPartyDepositData,
  IWithdrawalsData,
  IWithdrawalsState
} from "data/models/withdrawals";

const meta: IMeta = {
  nextPage: 0,
  total: 0,
  previousPage: 0,
  pageCount: 10,
  page: 1,
  limit: 10,
  withdrawalsStats: []
};

const initialState: IWithdrawalsState = {
  allWithdrawals: {
    data: [],
    meta,
    loading: false,
    error: "",
    status: "queued"
  },
  allThirdPartyDeposits: {
    data: [],
    meta,
    loading: false,
    error: "",
    status: "pending"
  },
  allRefunds: {
    data: [],
    meta,
    loading: false,
    error: "",
    status: "pending"
  }
};

const WithdrwalsSlice = createSlice({
  name: "withdrawals",
  initialState,
  reducers: {
    getAllWithdrawalsBegin: (state) => {
      state.allWithdrawals.loading = true;
      state.allWithdrawals.error = "";
    },
    getAllWithdrawalsSuceess: (
      state,
      action: PayloadAction<{
        data: IWithdrawalsData[];
        meta: IMeta;
      }>
    ) => {
      state.allWithdrawals.data = action.payload.data;
      state.allWithdrawals.meta = action.payload.meta;
      state.allWithdrawals.loading = false;
      state.allWithdrawals.error = "";
    },
    getAllWithdrawalsFailed: (state, action: PayloadAction<string>) => {
      state.allWithdrawals.loading = false;
      state.allWithdrawals.error = action.payload;
    },
    setWithdrawalStatus: (state, action: PayloadAction<string>) => {
      if (state.allWithdrawals.status === action.payload) {
        state.allWithdrawals.status = "";
      } else {
        state.allWithdrawals.status = action.payload;
      }
    },

    getAllThirdPartyDepositsBegin: (state) => {
      state.allThirdPartyDeposits.loading = true;
      state.allThirdPartyDeposits.error = "";
    },
    getAllThirdPartyDepositsSuceess: (
      state,
      action: PayloadAction<{
        data: IThirdPartyDepositData[];
        meta: IMeta;
      }>
    ) => {
      state.allThirdPartyDeposits.data = action.payload.data;
      state.allThirdPartyDeposits.meta = action.payload.meta;
      state.allThirdPartyDeposits.loading = false;
      state.allThirdPartyDeposits.error = "";
    },
    getAllThirdPartyDepositsFailed: (state, action: PayloadAction<string>) => {
      state.allThirdPartyDeposits.loading = false;
      state.allThirdPartyDeposits.error = action.payload;
    },
    setThirdPartyDeposits: (state, action: PayloadAction<string>) => {
      if (state.allThirdPartyDeposits.status === action.payload) {
        state.allThirdPartyDeposits.status = "";
      } else {
        state.allThirdPartyDeposits.status = action.payload;
      }
    },

    getAllRefundsBegin: (state) => {
      state.allRefunds.loading = true;
      state.allRefunds.error = "";
    },
    getAllRefundsSuceess: (
      state,
      action: PayloadAction<{
        data: IRefundData[];
        meta: IMeta;
      }>
    ) => {
      state.allRefunds.data = action.payload.data;
      state.allRefunds.meta = action.payload.meta;
      state.allRefunds.loading = false;
      state.allRefunds.error = "";
    },
    getAllRefundsFailed: (state, action: PayloadAction<string>) => {
      state.allRefunds.loading = false;
      state.allRefunds.error = action.payload;
    },
    setRefundStatus: (state, action: PayloadAction<string>) => {
      if (state.allRefunds.status === action.payload) {
        state.allRefunds.status = "";
      } else {
        state.allRefunds.status = action.payload;
      }
    }
  }
});

export const actions = WithdrwalsSlice.actions;

export default WithdrwalsSlice.reducer;
