import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import auth from "./reducers/auth.reducer";
import dashboard from "./reducers/dashboard.reducer";
import transactions from "./reducers/transactions.reducer";
import settings from "./reducers/settings.reducer";
import users from "./reducers/user.reducer";
import wallets from "./reducers/wallet.reducer";
import roles from "./reducers/roles.reducer";
import lien from "./reducers/lien.reducer";
import recipient from "./reducers/recipients.reducer";
import teamManagement from "./reducers/team-manangement.reducer";
import featureManagement from "./reducers/feature-management.reducer";
import auditTrail from "./reducers/audit.reducer";
import withdrawals from "./reducers/withdrawals.reducer";

const rootReducer = combineReducers({
  auth,
  dashboard,
  transactions,
  settings,
  users,
  wallets,
  roles,
  lien,
  recipient,
  teamManagement,
  featureManagement,
  auditTrail,
  withdrawals
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: []
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);
