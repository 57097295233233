import { tokenKey } from "./expiry-session";

export const saveToken = (token: string) => {
  window.localStorage.setItem(tokenKey, token);
};
export const removeToken = () => window.localStorage.removeItem(tokenKey);

export const getToken = () => window.localStorage.getItem(tokenKey);

class LocalStorage {
  static get = (key: string) => {
    if (typeof window === "undefined") return;

    const value = localStorage.getItem(key);

    return value ? JSON.parse(value) : null;
  };

  static set = (key: string, value: any) => {
    if (typeof window === "undefined") return;

    localStorage.setItem(key, JSON.stringify(value));
  };

  static remove = (key: string) => {
    if (typeof window === "undefined") return;

    window.localStorage.removeItem(key);
  };
}

export default LocalStorage;
