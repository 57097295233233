import {
  Th as ChakraTh,
  TableBodyProps,
  Tbody as ChakraTbody,
  TableColumnHeaderProps
} from "@chakra-ui/react";

export const Tbody = (props: TableBodyProps) => (
  <ChakraTbody fontSize={14} {...props} />
);

export const Th = (props: TableColumnHeaderProps) => (
  <ChakraTh color="gray.800" fontSize={16} textTransform="unset" {...props} />
);
