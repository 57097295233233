import { AxiosResponse } from "axios";
import instance, { authInstance, instancePaydayAuth } from "data/axios-setup";
import {
  encodeAssertResponse,
  encodeAttestationResponse
} from "data/utils/webauthn";

class AuthService {
  async generate2FA(body: {
    email: string;
  }): Promise<AxiosResponse<{ data: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePaydayAuth.post("/2fa/generate-admin", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async loginAdminUser(body: { email: string; password: string }): Promise<
    AxiosResponse<{
      message: string;
      data: {
        email: string;
        firstName: string;
        enable2FA: boolean;
        code: string;
      };
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePaydayAuth.post("/admin/login", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async twoFALogin(body: { email: string; code: string }): Promise<
    AxiosResponse<{
      data: {
        email: string;
        firstName: string;
        token: string;
      };
      status: string;
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePaydayAuth.post("/login/verify", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async twoFAAuthLogin(body: {
    email: string;
    code: string;
    auth2FaToken: string;
  }): Promise<
    AxiosResponse<{
      data: {
        email: string;
        firstName: string;
        token: string;
      };
      status: string;
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePaydayAuth.post("/2fa/authenticate", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async adminCreate(body: { email: string; role: string }): Promise<
    AxiosResponse<{
      token: string;
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post("/admin/auth/create", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async updatePassword(body: {
    email: string;
    password: string;
    token: string;
  }): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await authInstance.post(
          "/admin/auth/update-password",
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async terminateAdmin(body: { id: number }): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const { id } = body;
        const res = await instance.post(`/admin/auth/terminate/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async recoverPassword(body: { email: number }): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`/admin/auth/recover-password`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async webAuthnAttestationBegin(): Promise<
    AxiosResponse<{ data: PublicKeyCredentialCreationOptions }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/webauthn/attestate/begin");
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async webAuthnAssertionBegin(
    email: string
  ): Promise<AxiosResponse<{ data: PublicKeyCredentialRequestOptions }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/webauthn/assert/begin/${email}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async webAuthnAttestationEnd(
    credential: PublicKeyCredential
  ): Promise<AxiosResponse<PublicKeyCredentialCreationOptions>> {
    return new Promise(async (resolve, reject) => {
      try {
        const body = encodeAttestationResponse(credential);
        const res = await instance.post("/admin/webauthn/attestate/end", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async webAuthnAssertionEnd(
    email: string,
    credential: PublicKeyCredential
  ): Promise<
    AxiosResponse<{
      token: string;
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const body = encodeAssertResponse(email, credential);
        const res = await instance.post("/admin/webauthn/assert/end", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async disableWenAuthn() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.delete("/admin/webauthn/disable");
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new AuthService();
