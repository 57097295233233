import { Avatar } from "@chakra-ui/react";
import ReactCountryFlag from "react-country-flag";
import usdtAvatar from "app/assets/svg/usdt.svg";
import cusdAvatar from "app/assets/svg/cusd.svg";
import usdcAvatar from "app/assets/svg/usdc.svg";

export const useCurrencyFlag = () => {
  const getCurrencyFlag = (currency: string, size?: string) => {
    return currency.toLowerCase() === "usdt" ? (
      <Avatar src={usdtAvatar} h="6" w="6" fontSize="xs" />
    ) : currency.toLowerCase() === "usdc" ? (
      <Avatar src={usdcAvatar} h="6" w="6" fontSize="xs" />
    ) : currency.toLowerCase() === "cusd" ? (
      <Avatar src={cusdAvatar} h="6" w="6" fontSize="xs" />
    ) : currency.toLowerCase() === "usdt-tron" ? (
      <Avatar src={usdtAvatar} h="6" w="6" fontSize="xs" />
    ) : (
      <ReactCountryFlag
        countryCode={currency.substring(0, 2).toUpperCase()}
        svg
        style={{
          height: "16px",
          width: size ? size : "18px",
          borderRadius: "4px"
        }}
      />
    );
  };

  return { getCurrencyFlag };
};
