import React, { useState } from "react";
import Refresh from "views/components/refresh";
import { useSearchParams } from "react-router-dom";
import TransactionsTable from "./tables/transactions";
import { TabLink } from "views/components/tabs/transaction";
import { groupTransactionCallers } from "data/utils/transactions";
import { usePayDayTransaction } from "app/hooks/use-transactions";
import { Box, Heading, Stack, TabList, Tabs } from "@chakra-ui/react";
import { ChangeraTransactionType as Type } from "data/models/transaction";

export const TransactionContainer = () => {
  const {
    transactions,
    transactionCallers,
    dispatchGetAllTransactions,
    dispatchGetTransactionCallers
  } = usePayDayTransaction();
  const [params, setParams] = useSearchParams();
  const type: Type = params.get("type") as any;
  const [searchString, setSearchString] = useState("");

  const handleRefresh = () => {
    setSearchString("");
    dispatchGetAllTransactions({ caller: type || "caller" });
  };

  const callersToRemove = [
    "the-peer-debit",
    "the-peer-credit",
    "monieme-debit",
    "fincra-credit",
    "fincra-debit",
    "crypto-top-up"
  ];
  let filteredCallers = transactionCallers?.data?.filter(
    (el) => !callersToRemove.includes(el)
  );

  const { callers, filters } = groupTransactionCallers(filteredCallers);

  React.useEffect(() => {
    if (type) return;
    setParams({ type: "refund" });
  }, []);

  React.useEffect(() => {
    dispatchGetTransactionCallers();
  }, []);

  return (
    <div className="p-4 md:p-8">
      <Box>
        <Box
          mb={6}
          display="flex"
          position="relative"
          alignItems="center"
          justifyContent="space-between"
        >
          <Heading
            fontWeight={700}
            color="gray.900"
            lineHeight="28px"
            fontFamily="Karla"
            fontSize={[21, 24]}
          >
            Transactions
          </Heading>

          <Refresh onClick={handleRefresh} isLoading={transactions.isLoading} />
        </Box>
      </Box>

      <div>
        <Tabs>
          <TabList position="relative">
            <Stack direction="row" overflowX="scroll">
              {callers?.map((c) => (
                <TabLink
                  key={c}
                  type={c}
                  noOfLines={1}
                  className="truncate capitalize"
                >
                  {c}
                </TabLink>
              ))}
            </Stack>
          </TabList>

          <div className="mt-4">
            <TransactionsTable
              subCallers={filters}
              searchString={searchString}
              setSearchString={setSearchString}
            />
          </div>
        </Tabs>
      </div>
    </div>
  );
};
