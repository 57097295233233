import {
  IImposeLien,
  ILienResData,
  IGetLienParams,
  IGetLienUpdate,
  ILienUpdateData
} from "data/models/lien";
import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { IPagination } from "data/models/base";

class WalletService {
  async getAllLien(
    params: IGetLienParams
  ): Promise<AxiosResponse<{ data: ILienResData[]; pagination: IPagination }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/lien", { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleLien({
    id,
    params
  }: IGetLienUpdate): Promise<
    AxiosResponse<{ data: ILienUpdateData[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/lien/${id}`, { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async imposeLien(
    callSign: string,
    body: IImposeLien
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`/admin/lien/${callSign}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async deleteLien(id: string): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/lien/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new WalletService();
