import { AxiosResponse } from "axios";
import instance, { instancePayday } from "data/axios-setup";
import { IPagination } from "data/models/base";
import { Team } from "data/models/team-management";

class TeamManagementService {
  async getAllTeamMembers(
    callsign: string,
    params: any
  ): Promise<AxiosResponse<{ data: Team[]; pagination: IPagination }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(
          `admin/team-management/all/${callsign}`,
          { params }
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllAdminTypes(): Promise<
    AxiosResponse<{ data: string[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get(`/available/admin-type`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getTeamMember(memberId: number): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`admin/team-management/${memberId}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async deactivateTeamMember(memberId: number): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.delete(`admin/team-management/${memberId}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getTeamMemberAuditLogs(
    memberId: number,
    params: any
  ): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(
          `admin/team-management/team-audit/${memberId}`,
          { params }
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new TeamManagementService();
