import {
  error,
  success,
  logoutBegin,
  loadingStop,
  loadingBegin,
  loginSuccess,
  settwoFAStep,
  logoutSuccess
} from "../reducers/auth.reducer";

import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import authService from "data/services/auth.service";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import ExpirySession, { tokenKey } from "data/utils/expiry-session";
// import QRCode from "qrcode";

export const generate2FA = (email: string, settwofaCode: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await authService.generate2FA({ email });
      settwofaCode(res.data.data);
      dispatch(settwoFAStep(1));
      dispatch(loadingStop());
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const login2FA = (
  navigate: NavigateFunction,
  from: string,
  data: {
    email: string;
    code: string;
  }
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());

      const res = await authService.twoFALogin(data);

      ExpirySession.set(tokenKey, res.data?.data?.token);

      dispatch(loginSuccess(true));
      navigate(from, { replace: true });
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const login2FAAuth = (
  navigate: NavigateFunction,
  from: string,
  data: {
    email: string;
    code: string;
    auth2FaToken: string;
  }
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());

      const res = await authService.twoFAAuthLogin(data);

      ExpirySession.set(tokenKey, res.data?.data?.token);

      dispatch(loginSuccess(true));
      navigate(from, { replace: true });
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const logOut = (navigate: NavigateFunction) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(logoutBegin());
      dispatch(logoutSuccess());
      ExpirySession.clear();
      navigate("/auth/login", { replace: true });
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const webAuthnAttestationBegin = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await authService.webAuthnAttestationBegin();
      return res.data.data;
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const webAuthnAssertionBegin = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await authService.webAuthnAssertionBegin(email);
      return res.data.data;
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const webAuthnAttestationEnd = (credential: PublicKeyCredential) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      await authService.webAuthnAttestationEnd(credential);
      dispatch(success());
      toast.success("Success");
      return true;
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const webAuthnAssertionEnd = (
  from: string,
  navigate: NavigateFunction,
  email: string,
  credential: PublicKeyCredential
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      const res = await authService.webAuthnAssertionEnd(email, credential);
      ExpirySession.set(tokenKey, res.data.token);
      dispatch(loginSuccess(true));
      navigate(from, { replace: true });
      toast.success("Success");
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};

export const disableWebAuthn = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loadingBegin());
      await authService.disableWenAuthn();
      dispatch(success());
      toast.success("Success");
      return true;
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(error());
      toast.error(msg);
    }
  };
};
