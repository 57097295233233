import { Box } from "@chakra-ui/react";
import { Link, LinkProps, useResolvedPath, useMatch } from "react-router-dom";
const SideBarLink = ({ children, to, ...props }: LinkProps): JSX.Element => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  return (
    <Link to={to} {...props}>
      <Box
        _after={{
          position: "absolute",
          top: "0",
          left: "calc(100% - 5px)",
          width: "5px",
          height: "100%",
          content: "''",
          background: match ? "white" : "transparent"
        }}
        className={`relative py-4 px-6 hover:bg-[#62687E] hover:text-white hover:after:bg-white transition-colors flex items-center gap-4 ${
          match ? "bg-[#62687E] text-white" : "bg-transparent text-gray-400"
        }`}
      >
        {children}
      </Box>
    </Link>
  );
};

export const SideBarLinkHome = ({
  children,
  to,
  ...props
}: LinkProps): JSX.Element => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link to={to} {...props}>
      <Box
        _after={{
          position: "absolute",
          top: "0",
          left: "calc(100% - 5px)",
          width: "5px",
          height: "100%",
          content: "''",
          background: match ? "white" : "transparent"
        }}
        className={`relative py-4 px-6 hover:bg-gray-800 hover:text-white hover:after:bg-white transition-colors flex items-center gap-4 ${
          match ? "bg-gray-800 text-white" : "bg-transparent text-gray-400"
        }`}
      >
        {children}
      </Box>
    </Link>
  );
};

export default SideBarLink;
