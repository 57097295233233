import { keyframes, usePrefersReducedMotion } from "@chakra-ui/react";

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }`;

export const useSpinAnimation = () => {
  const prefersReducedMotion = usePrefersReducedMotion();

  return prefersReducedMotion ? undefined : `${spin} infinite 1s linear`;
};
