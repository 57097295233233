import { AxiosResponse } from "axios";
import instance, { instancePayday } from "data/axios-setup";
import { s3Config } from "data/config/s3config";
import { IPaginationPayday } from "data/models/base";
import {
  AuthorizationData,
  ConfigData,
  ChargesResponse,
  IGetAllChargesParams,
  IUpdatePasswordBody,
  IUpdateProfileBody,
  RatesResponse,
  S3UploadResponse,
  IGetAllLimitsParams,
  LimitsResponse
} from "data/models/settings";

import S3 from "react-aws-s3-typescript";

class SettingsService {
  async uploadFileS3(
    file: File | any,
    fileName: string | null = null
  ): Promise<S3UploadResponse> {
    const client = new S3(s3Config);

    return new Promise(async (resolve, reject) => {
      try {
        const res = await client.uploadFile(file, fileName ?? file.name);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async deleteFileS3(filePath: string): Promise<void> {
    const client = new S3(s3Config);

    return new Promise(async (resolve, reject) => {
      try {
        const res = await client.deleteFile(filePath);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async uploadImage(body: { image: string }): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put("/admin/account/profile-image", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async deleteImage(): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put("/admin/account/delete-image");
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async updatePassword(body: IUpdatePasswordBody): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put("/admin/account/update-password", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async updateProfile(body: IUpdateProfileBody): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put("/admin/account/profile", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAuthorizations(params: any): Promise<
    AxiosResponse<{
      data: AuthorizationData[];
      meta: IPaginationPayday;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get("/only", {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
  async getConfigs(params: any): Promise<
    AxiosResponse<{
      data: {
        data: ConfigData[];
        meta: IPaginationPayday;
      };
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get("/settings", {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async updateConfig(
    id: string,
    body: any
  ): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.patch(`/settings/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllCharges(params: IGetAllChargesParams): Promise<
    AxiosResponse<{
      data: { data: ChargesResponse[]; meta: IPaginationPayday };
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get("/charges", {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllLimits(
    params: IGetAllLimitsParams
  ): Promise<
    AxiosResponse<{ data: { data: LimitsResponse[]; meta: IPaginationPayday } }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get("/limits", { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllRates(params: IGetAllChargesParams): Promise<
    AxiosResponse<{
      data: { data: RatesResponse[]; meta: IPaginationPayday };
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get("/currency-rates", {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async editCharges(
    id: string,
    body: any
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.patch(`/charges/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async editLimit(
    id: string,
    body: any
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.patch(`/limits/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async editRates(
    id: string,
    body: any
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.patch(`/currency-rates/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addRole(
    id: string,
    body: { role: string }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(`/add-role/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async removeRole(
    id: string,
    body: { role: string }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(`/remove-role/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addAdmin(body: {
    userId: string;
    roles: string[];
  }): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(`/add-admin`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
  async removeAdmin(id: string): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.patch(`/remove-admin/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new SettingsService();
