import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import {
  IAddRecipientBody,
  IGetRecipientsParams,
  IRecipient
} from "data/models/recipients";
import { IPagination } from "data/models/base";

class RecipientService {
  async getRecipients(
    params: IGetRecipientsParams
  ): Promise<AxiosResponse<{ data: IRecipient[]; pagination: IPagination }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`/admin/recipients`, {
          params
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addRecipient(body: IAddRecipientBody) {
    return new Promise<AxiosResponse<{ data: any; message: string }>>(
      async (resolve, reject) => {
        try {
          const res = await instance.post(`/recipient`, body);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }
}

export default new RecipientService();
