import { Link, Outlet } from "react-router-dom";
import Logo from "app/assets/img/logo-dark.png";

const AuthLayout = (): JSX.Element => {
  return (
    <div className="h-screen px-4 py-8 overflow-y-auto mdx:py-16 mdx:px-16 grow bg-black">
      <div className="flex justify-center">
        <Link to={"/"}>
          <img src={Logo} className="py-2 w-24 lg:w-32 invert" />
        </Link>
      </div>
      <div className="flex items-center justify-center w-full mt-10 mdx:mt-12">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
