import { Box, BoxProps, Icon } from "@chakra-ui/react";
import { Refresh as RefreshIcon } from "iconsax-react";
import { useSpinAnimation } from "data/utils/animations";

type Props = { isLoading?: boolean; onClick?: () => void } & BoxProps;

const Refresh = ({ onClick, isLoading, ...props }: Props) => {
  const spin = useSpinAnimation();
  return (
    <Box
      p={2}
      right={0}
      bottom={0}
      cursor="pointer"
      position="absolute"
      {...props}
    >
      <Icon
        as={RefreshIcon}
        cursor="pointer"
        onClick={onClick}
        color="success.600"
        animation={isLoading ? spin : "unset"}
      />
    </Box>
  );
};

export default Refresh;
