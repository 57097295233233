import { ITransaction } from "data/models/transaction";
import { useState } from "react";
import { TransactionDetails } from "views/components/transaction/transactions-details";
import {
  getAirtimeDataTransactions,
  getAllTransactions,
  getAllWithdrawals,
  getCardTransactions,
  getConvertFundsTransactions,
  getTransactionCallers,
  getTransactions,
  getUsersTransactions,
  setTransactionFilter
} from "data/store";
import { useAppDispatch, useAppSelector } from "./useReduxState";
import { ChangeraTransactionType } from "data/models/transaction";
import { filterInitialState } from "data/store/reducers/transactions.reducer";

export const useTransactions = () => {
  const [transactionDetails, setTransactionDetails] =
    useState<ITransaction | null>(null);

  const onOpenTransactionDetails = (data: ITransaction) => {
    setTransactionDetails(data);
  };

  const onCloseTransactionDetails = () => {
    setTransactionDetails(null);
  };

  const TransactionDetailsModal = () => {
    if (!transactionDetails) return null;
    return TransactionDetails({
      isOpen: !!transactionDetails,
      onClose: onCloseTransactionDetails,
      details: transactionDetails as any
    });
  };

  return {
    isTransactionsDetailOpen: !!transactionDetails,
    onOpenTransactionDetails,
    onCloseTransactionDetails,
    TransactionDetailsModal
  };
};

type IUseDispatchTransactionsProps = {
  businessId?: number;
};

export const useDispatchTransactions = ({
  businessId
}: IUseDispatchTransactionsProps) => {
  const dispatch = useAppDispatch();
  const [
    isLoadingFx,
    isLoadingFunding,
    isLoadingWithdrawal,
    isLoadingPaySomeone,
    isLoadingAirtimeData,
    isLoadingConversion,
    isLoadingVirtualCards
  ] = useAppSelector((state) => [
    state?.transactions?.status?.getTransactions,
    state?.transactions?.status?.getTransactions,
    state?.transactions?.status?.getAllWithdrawals,
    state?.transactions?.status?.getAllWithdrawals,
    state?.transactions?.status?.getAirtimeDataTransactions,
    state?.transactions?.status?.getConversionTransactions,
    state?.transactions?.status?.getCardTransactions
  ]);

  const status = {
    isLoadingFx,
    isLoadingWithdrawal,
    isLoadingPaySomeone,
    isLoadingFunding,
    isLoadingAirtimeData,
    isLoadingConversion,
    isLoadingVirtualCards
  };

  const dispatchResetTransactionFilter = () => {
    dispatch(setTransactionFilter(filterInitialState));
  };

  const dispatchFunding = () => {
    dispatch(
      getTransactions({
        business: businessId,
        changeraTransactionType: ChangeraTransactionType.FUNDING_WALLET
      })
    );
  };

  const dispatchWithdrawal = () => {
    dispatch(
      getAllWithdrawals({
        business: businessId,
        type: ChangeraTransactionType.WITHDRAWAL
      })
    );
  };

  const dispatchPaySomeone = () => {
    dispatch(
      getAllWithdrawals({
        business: businessId,
        type: ChangeraTransactionType.PAY_MONEY
      })
    );
  };

  const dispatchAirtimeData = () => {
    dispatch(
      getAirtimeDataTransactions({
        business: businessId
      })
    );
  };

  const dispatchConversion = () => {
    dispatch(
      getConvertFundsTransactions({
        business: businessId
      })
    );
  };

  const dispatchVirtualCards = () => {
    dispatch(
      getCardTransactions({
        business: businessId
      })
    );
  };

  const dispatchFX = () => {
    dispatch(
      getTransactions({
        business: businessId,
        subType: ChangeraTransactionType.FX
      })
    );
  };

  const actions = {
    dispatchFX,
    dispatchWithdrawal,
    dispatchPaySomeone,
    dispatchFunding,
    dispatchAirtimeData,
    dispatchConversion,
    dispatchVirtualCards,
    dispatchResetTransactionFilter
  };

  return { actions, status };
};

export const usePayDayTransaction = () => {
  const dispatch = useAppDispatch();
  const [transactions, transactionCallers] = useAppSelector((state) => [
    state.transactions.getAllTransactions,
    state.transactions.getTransactionCallers
  ]);

  const dispatchGetAllTransactions = async (params: any) => {
    return await dispatch(getAllTransactions(params));
  };

  const dispatchGetTransactionCallers = async () => {
    return await dispatch(getTransactionCallers());
  };

  const dispatchGetUsersTransactions = async (userId: string, params: any) => {
    return await dispatch(getUsersTransactions(userId, params));
  };

  return {
    transactions,
    transactionCallers,
    dispatchGetAllTransactions,
    dispatchGetUsersTransactions,
    dispatchGetTransactionCallers
  };
};
