export const supportedCountries = [
  {
    id: 154,
    name: "Nigeria",
    minimum_transfer: 1000,
    abbr: "NGA",
    flag: "🇳🇬",
    code: "NG",
    currency: "NGN",
    call_code: 234,
    isoCode: "NG"
  },
  {
    id: 81,
    name: "Ghana",
    minimum_transfer: 6,
    abbr: "GHA",
    currency: "GHS",
    flag: "🇬🇭",
    code: "GH",
    call_code: 233,
    isoCode: "GH"
  },
  {
    id: 110,
    name: "Kenya",
    minimum_transfer: 0,
    abbr: "KEN",
    flag: "🇰🇪",
    code: "KE",
    currency: "KES",
    call_code: 254,
    isoCode: "KE"
  },
  {
    id: 227,
    name: "United States",
    minimum_transfer: 2,
    abbr: "USA",
    currency: "USD",
    flag: "🇺🇸",
    code: "US",
    status: 0,
    call_code: null,
    isoCode: "US"
  },
  {
    id: 226,
    name: "United Kingdom",
    minimum_transfer: 0,
    abbr: "GBR",
    code: "GB",
    currency: "GBP",
    flag: "🇬🇧",
    call_code: 44,
    isoCode: "GB"
  },
  {
    id: 34,
    name: "Canada",
    minimum_transfer: 0,
    abbr: "CA",
    code: "CA",
    currency: "CAD",
    flag: "🇨🇦",
    call_code: 1,
    isoCode: "CA"
  }
];

export interface ICountry {
  id: number;
  name: string;
  minimum_transfer: number;
  abbr: string;
  flag: string;
  code: string;
  currency: string;
  call_code: number | null;
  status?: number;
}

interface IObjectKeys {
  [key: string]: {
    id: number;
    name: string;
    minimum_transfer: number;
    abbr: string;
    flag: string;
    currency: string;
    call_code: number | null;
    status?: number;
  };
}

export const supportedCountriesMap: IObjectKeys = {
  ngn: {
    id: 154,
    name: "Nigeria",
    minimum_transfer: 1000,
    abbr: "NGA",
    flag: "🇳🇬",
    currency: "NGN",
    call_code: 234
  },
  ghs: {
    id: 81,
    name: "Ghana",
    minimum_transfer: 6,
    abbr: "GHA",
    currency: "GHS",
    flag: "🇬🇭",
    call_code: 233
  },
  kes: {
    id: 110,
    name: "Kenya",
    minimum_transfer: 0,
    abbr: "KEN",
    flag: "🇰🇪",
    currency: "KES",
    call_code: null
  },
  usd: {
    id: 227,
    name: "United States",
    minimum_transfer: 2,
    abbr: "USA",
    currency: "USD",
    flag: "🇺🇸",
    status: 0,
    call_code: null
  },
  gbp: {
    id: 226,
    name: "United Kingdom",
    minimum_transfer: 0,
    abbr: "GBR",
    currency: "GBP",
    flag: "🇬🇧",
    call_code: 44
  }
};
