import { Stack, Text } from "@chakra-ui/react";
import NoDataSVG from "app/assets/svg/no-data.svg";

const NoData = ({
  text,
  mt = 24,
  width = "172px",
  height = "128px"
}: {
  mt?: number;
  text: string;
  width?: string;
  height?: string;
}) => (
  <Stack
    mt={mt}
    spacing={8}
    width="full"
    alignItems="center"
    justifyContent={"center"}
  >
    <img alt="" src={NoDataSVG} style={{ width, height }} />
    <Text color="#444B59">{text}</Text>
  </Stack>
);

export default NoData;
