import { AxiosResponse } from "axios";
import { instancePayday } from "data/axios-setup";
import {
  AuditTrailData,
  IGetAllAuditTrailParams
} from "data/models/audit-trail";
import { IPaginationPayday } from "data/models/base";

class AuditService {
  async getAllAuditTrail(params: IGetAllAuditTrailParams): Promise<
    AxiosResponse<{
      data: { list: AuditTrailData[]; pagination: IPaginationPayday };
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get("/audit", {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new AuditService();
