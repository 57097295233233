import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import {
  IBusinessMobileMoneyWalletParams,
  IGetAllBusinessKycParams,
  IGetAllBusinessParams,
  IGetAllUsersParams,
  IUpdateVirtualCardsBody
} from "data/models/user";
import settingsService from "data/services/settings.service";
import transactionsService from "data/services/transactions.service";
import userService from "data/services/user.service";
import toast from "react-hot-toast";
import { actions } from "../reducers/user.reducer";

export const getAllBusinesses = (params: IGetAllBusinessParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllBusinessBegin());
      const res = await userService.getAllBusiness(params);
      dispatch(actions.getAllBusinessSuceess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllBusinessFailed(msg));
    }
  };
};

export const getAllUserStatuses = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getUserStatusesBegin());
      const res = await userService.getUserStatuses();
      dispatch(actions.getUserStatusesSuccess(res.data.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getUserStatusesFailed(msg));
    }
  };
};

export const getAllBusinessKyc = (params: IGetAllBusinessKycParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllBusinessKycBegin());
      const res = await userService.getAllBusinessKyc(params);
      dispatch(actions.getAllBusinessKycSuccess(res.data.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllBusinessKycFailed(msg));
    }
  };
};

export const getAllPaydayUsers = (params: Record<string, any>) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllBusinessKycBegin());
      const res = await userService.getAllPaydayUsers(params);
      dispatch(actions.getAllBusinessKycSuccess(res.data.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllBusinessKycFailed(msg));
    }
  };
};

export const searchAllUsers = (params: IGetAllBusinessKycParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllBusinessKycBegin());
      const res = await userService.searchAllUsers(params);
      dispatch(actions.getAllBusinessKycSuccess(res.data.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllBusinessKycFailed(msg));
    }
  };
};

export const getAllUsers = (params: IGetAllUsersParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllUsersBegin());
      const res = await userService.getAllUsers(params);
      dispatch(actions.getAllUsersSuceess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllUsersFailed(msg));
    }
  };
};

export const getSingleBusiness = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSingleBusinessBegin());
      const res = await userService.getSingleBusiness(id);
      dispatch(actions.getSingleBusinessSuceess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getSingleBusinessFailed(msg));
      dispatch(actions.getSingleBusinessReset());
    }
  };
};

export const getSingleUserKyc = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSingleBusinessBegin());
      const res = await userService.getSingleUserKyc(id);
      dispatch(actions.getSingleUserKycSuceess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getSingleBusinessFailed(msg));
      dispatch(actions.getSingleBusinessReset());
    }
  };
};

export const getBusinessVirtualAccounts = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getBusinessVirtualAccountsBegin());
      const res = await userService.getBusinessVirtualAccountss(id);
      dispatch(actions.getBusinessVirtualAccountsSuccess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getBusinessVirtualAccountsFailed(msg));
    }
  };
};

export const getBusinessVirtualCards = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getBusinessVirtualCardsBegin());
      const res = await userService.getBusinessVirtualCards(id);
      dispatch(actions.getBusinessVirtualCardsSuccess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getBusinessVirtualCardsFailed(msg));
    }
  };
};

export const updateBusinessVirtualCards = (body: IUpdateVirtualCardsBody) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.updateBusinessVirtualCardBegin());
      const res = await userService.updateBusinessVirtualCards(body);
      dispatch(actions.updateBusinessVirtualCardEnd());
      return res.data.message;
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.updateBusinessVirtualCardEnd(msg));
    }
  };
};

export const getSingleBusinessTxSummary = (
  id: number,
  params: { currency: string; from?: string; to?: string }
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSingleBusinessTxSummaryBegin());
      const res = await transactionsService.getTransactionSummaryV2(id, params);
      dispatch(actions.getSingleBusinessTxSummarySuceess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getSingleBusinessTxSummaryFailed(msg));
    }
  };
};

export const getBusinessMobileMoneyWallets = (
  params: IBusinessMobileMoneyWalletParams
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getBusinessMobileMoneyWalletsBegin());
      const res = await userService.getBusinessMobileMoneyWallets(params);
      dispatch(actions.getBusinessMobileMoneyWalletsSuceess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getBusinessMobileMoneyWalletsFailed(msg));
    }
  };
};

export const getBusinessBank = (params: IBusinessMobileMoneyWalletParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getBusinessBankBegin());
      const res = await userService.getBanks(params);
      dispatch(actions.getBusinessBankSuceess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getBusinessBankFailed(msg));
    }
  };
};

export const uploadBusinessDocumentsS3 = (file: File, filename: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.BusinessDocsUploadBegin());
      const response = await settingsService.uploadFileS3(file, filename);

      const regex = /.com\/\//gi;
      let image = response.location;
      image = image.replaceAll(regex, ".com/");

      dispatch(actions.BusinessDocsUploadSuccess());
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.BusinessDocsUploadFailed(msg));
    }
  };
};

export const uploadBusinessDocuments = (
  id: number,
  certificateOfRegistration: string[],
  callback: () => void,
  onUploadProgress?: (progressEvent: number) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.BusinessDocsUploadBegin());

      const res = await userService.uploadBusinessDocuments(
        id,
        {
          certificateOfRegistration
        },
        onUploadProgress
      );

      dispatch(actions.BusinessDocsUploadSuccess());
      toast.success(res.data.message);
      callback();
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.BusinessDocsUploadFailed(msg));
    }
  };
};

export const uploadBusinessDocumentsV2 = (
  id: number,
  uploadKey: string,
  fileUrl: string,
  callback: () => void,
  onUploadProgress?: (progressEvent: number) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.BusinessDocsUploadBegin());

      const res = await userService.uploadBusinessDocuments(
        id,
        {
          [uploadKey]: fileUrl
        },
        onUploadProgress
      );

      dispatch(actions.BusinessDocsUploadSuccess());
      toast.success(res.data.message);
      callback();
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.BusinessDocsUploadFailed(msg));
    }
  };
};
