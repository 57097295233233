import { UserProfile } from "data/models/auth";
import { DashboardState, ISummary } from "data/models/dashboard";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: DashboardState = {
  userProfile: null,
  loading: false,
  summary: null,
  dashboardError: false
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    gettingUserProfile: (state: DashboardState) => {
      state.loading = true;
    },
    settingUser: (
      state: DashboardState,
      action: PayloadAction<UserProfile>
    ) => {
      state.userProfile = action.payload;
      state.loading = false;
    },
    dashboardError: (state: DashboardState) => {
      state.loading = false;
      state.dashboardError = true;
    },
    gettingSummary: (state: DashboardState) => {
      state.loading = true;
    },
    settingSummary: (
      state: DashboardState,
      action: PayloadAction<ISummary>
    ) => {
      state.summary = action.payload;
      state.loading = false;
    }
  }
});

export const {
  gettingUserProfile,
  settingUser,
  dashboardError,
  gettingSummary,
  settingSummary
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
