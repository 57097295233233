import {
  Box,
  Grid,
  Text,
  Stack,
  Modal,
  Button,
  Heading,
  ModalBody,
  ModalFooter,
  ModalContent,
  ModalOverlay,
  ModalCloseButton
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BaseModalProps } from "data/models/base";
import { ITransaction } from "data/models/transaction";
import getSymbolFromCurrency from "currency-symbol-map";

interface ITransactionDetails extends BaseModalProps {
  details: ITransaction;
}

export const TransactionDetails = ({
  isOpen,
  onClose,
  details
}: ITransactionDetails): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Heading fontSize={"xl"} pb="5" pt="5">
              Transaction Details
            </Heading>

            <Grid
              h="full"
              justifyContent={"space-between"}
              gridGap={"4"}
              templateColumns={[
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(auto-fit,minmax(2,1fr))"
              ]}
            >
              <Stack>
                <Text color={"gray.600"} fontSize={"sm"}>
                  Caller
                </Text>
                <Heading
                  fontSize="sm"
                  color="primary.900"
                  fontWeight="extrabold"
                  className="capitalize"
                >
                  {details?.caller?.replaceAll("-", " ")}
                </Heading>
              </Stack>
              <Stack>
                <Text color={"gray.600"} fontSize={"sm"}>
                  Currency
                </Text>
                <Heading
                  fontSize="sm"
                  color="primary.900"
                  fontWeight="extrabold"
                  className="uppercase"
                >
                  {details?.currency}
                </Heading>
              </Stack>
              <Stack>
                <Text color={"gray.600"} fontSize={"sm"}>
                  Swap Currency
                </Text>
                <Heading
                  fontSize="sm"
                  color="primary.900"
                  fontWeight="extrabold"
                  className="uppercase"
                >
                  {details?.metadata?.swapCurrency || "--"}
                </Heading>
              </Stack>
              <Stack>
                <Text color={"gray.600"} fontSize={"sm"}>
                  Amount
                </Text>
                <Heading
                  fontSize="sm"
                  color="primary.900"
                  fontWeight="extrabold"
                  className="uppercase"
                >
                  {getSymbolFromCurrency(details?.currency)}{" "}
                  {details?.amount?.toLocaleString() || "--"}
                </Heading>
              </Stack>
              <Stack>
                <Text color={"gray.600"} fontSize={"sm"}>
                  Previous Total
                </Text>
                <Heading
                  fontSize="sm"
                  color="primary.900"
                  fontWeight="extrabold"
                  className="uppercase"
                >
                  {getSymbolFromCurrency(details?.currency)}{" "}
                  {details?.previousTotal?.toLocaleString() || "--"}
                </Heading>
              </Stack>

              <Stack>
                <Text color={"gray.600"} fontSize={"sm"}>
                  Next Total
                </Text>
                <Heading
                  fontSize="sm"
                  color="primary.900"
                  fontWeight="extrabold"
                  className="uppercase"
                >
                  {getSymbolFromCurrency(details?.currency)}{" "}
                  {details?.nextTotal?.toLocaleString() || "--"}
                </Heading>
              </Stack>

              <Stack>
                <Text color={"gray.600"} fontSize={"sm"}>
                  User
                </Text>
                <Link
                  className="text-sm text-blue-600"
                  to={`/customers/${details?.userId}`}
                >
                  View User
                </Link>
              </Stack>
            </Grid>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="primary" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
