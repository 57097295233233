import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { instancePayday } from "data/axios-setup";
import { Dispatch } from "@reduxjs/toolkit";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import {
  dashboardError,
  gettingUserProfile,
  settingUser,
  gettingSummary,
  settingSummary
} from "../reducers/dashboard.reducer";

export const getUserProfile = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(gettingUserProfile());
      const res = await instancePayday.get("/current/user");
      if (res.status) {
        dispatch(settingUser(res.data.data));
      }
    } catch (err: any) {
      dispatch(dashboardError());
    }
  };
};

export const getSummary = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(gettingSummary());
      const res = await instancePayday.get("/statistics/users");
      if (res.status) {
        dispatch(settingSummary(res.data.data));
      }
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(dashboardError());
    }
  };
};
