import {
  onResponseSuccess,
  DEFAULT_STATUS_TYPE,
  DEFAULT_ERROR_MESSAGE
} from "data/error-mapping";
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import instance from "data/axios-setup";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/transactions.reducer";
import {
  ChangeraTransactionType,
  Filter,
  GenerateTransactionStatementBody
} from "data/models/transaction";
import transactionService from "data/services/transactions.service";

export const setTransactionFilter = (data: Partial<Filter>) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setTransactionFilter({ data }));
  };
};

export const getAllTransactions = (params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllTransactionsBegin());
      const res = await transactionService.getAllTransactions(params);
      dispatch(
        actions.getAllTransactionsSuccess({
          data: res.data.data,
          meta: res.data.meta
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllTransactionsFailed(msg));
    }
  };
};

export const getTransactionCallers = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getTransactionCallersBegin());
      const res = await transactionService.getTransactionCallers();
      dispatch(actions.getTransactionCallersEnd(res.data.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getTransactions" }));
    }
  };
};

export const getTransactions = (params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getTransactions"));
      const res = await transactionService.getTransactions(params);
      dispatch(
        actions.setTransactions({
          data: res.data.data,
          meta: res.data.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getTransactions" }));
    }
  };
};

export const getUsersTransactions = (userId: string, params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllTransactionsBegin());
      const res = await transactionService.getUsersTransactions(userId, params);
      dispatch(
        actions.getAllTransactionsSuccess({
          data: res.data.data.transactions.list,
          meta: res.data.data.transactions.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllTransactionsFailed(msg));
    }
  };
};

export const getAirtimeDataTransactions = (params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getAirtimeDataTransactions"));
      const res = await transactionService.getAirtimeDataTransactions(params);
      dispatch(
        actions.setAirtimeDataTransactions({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getAirtimeDataTransactions" }));
    }
  };
};

export const getConvertFundsTransactions = (params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getConversionTransactions"));
      const res = await transactionService.getConversionTransactions(params);
      dispatch(
        actions.setConverstionTransactions({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getConversionTransactions" }));
    }
  };
};

export const getAllWithdrawals = (params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getAllWithdrawals"));
      const res = await transactionService.getAllWithdrawals(params);

      if (params?.type === "withdrawal")
        dispatch(actions.setWithdrawalTransactionsInfo(res.data));

      if (params?.type === "pay-money")
        dispatch(actions.setWithdrawalSendMoneyInfo(res.data));

      dispatch(actions.setWithdrawalsInfo(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getAllWithdrawals" }));
    }
  };
};

export const changeWitdrawalStatus = (
  id: string,
  status: any,
  callback: () => void,
  onClose?: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.changingWitdrawalStatus());
      const res = await instance.put(
        `/admin/withdrawals/process/${id}`,
        status
      );
      onResponseSuccess(res, () => {
        toast.success("Status has been updated successfully");
        onClose();
        callback();
      });
      dispatch(actions.withdrawalStatusChanged());
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.error({ msg, for: "getAllWithdrawals" }));
      dispatch(actions.withdrawalStatusChanged());
      onClose();
    }
  };
};

export const getCardTransactions = (params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loading("getCardTransactions"));
      const res = await transactionService.getCardTransactions(params);
      dispatch(
        actions.setCardTransactions({
          data: res.data.data,
          pagination: res.data.pagination
        })
      );
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getCardTransactions" }));
    }
  };
};

export const setTransactionStatementStatus = (status: DEFAULT_STATUS_TYPE) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setTransactionStatementStatus(status));
  };
};

export const getTransactionStatement = (
  params: any,
  type: ChangeraTransactionType
) => {
  return async (dispatch: Dispatch) => {
    try {
      let res: any = {};
      dispatch(actions.setTransactionStatementStatus("loading"));
      if (type === ChangeraTransactionType.FUNDING_WALLET) {
        res = await transactionService.getTransactions(params);
        dispatch(
          actions.setTransactionStatement({
            type: "funding",
            data: res.data.data
          })
        );
      }

      if (type === ChangeraTransactionType.WITHDRAWAL) {
        res = await transactionService.getAllWithdrawals(params);
        dispatch(
          actions.setTransactionStatement({
            type: "withdrawal",
            data: res.data.data
          })
        );
      }

      if (type === ChangeraTransactionType.PAY_MONEY) {
        res = await transactionService.getAllWithdrawals(params);
        dispatch(
          actions.setTransactionStatement({
            type: "paySomeone",
            data: res.data.data
          })
        );
      }

      if (type === ChangeraTransactionType.AIRTIME_VTU) {
        res = await transactionService.getAirtimeDataTransactions(params);
        dispatch(
          actions.setTransactionStatement({
            type: "airtimeData",
            data: res.data.data
          })
        );
      }

      if (type === ChangeraTransactionType.FIAT_CONVERSION) {
        res = await transactionService.getConversionTransactions(params);
        dispatch(
          actions.setTransactionStatement({
            type: "conversion",
            data: res.data.data
          })
        );
      }

      if (type === ChangeraTransactionType.VIRTUAL_CARD) {
        res = await transactionService.getCardTransactions(params);
        dispatch(
          actions.setTransactionStatement({
            type: "card",
            data: res.data.data
          })
        );
      }
    } catch (err: any) {
      const error: AxiosError<{ message: string }> = err;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.error({ msg, for: "getTransactionStatement" }));
    }
  };
};

export const generateTransactionStatementV2 = (
  body: GenerateTransactionStatementBody,
  onOpen: () => void,
  businessId: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.setTransactionStatementStatus("loading"));
      await transactionService.generateTransactionStatementV2(body, businessId);
      onOpen();
      dispatch(actions.setTransactionStatementStatus("succeeded"));
    } catch (err: any) {
      const error: AxiosError<{ message: string }> = err;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.setTransactionStatementStatus("idle"));
      dispatch(actions.error({ msg, for: "getTransactionStatement" }));
    }
  };
};
