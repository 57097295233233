import {
  FeatureData,
  FeatureState,
  PaginationState
} from "data/models/feature-management";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: FeatureState = {
  addFeature: { error: "", loading: false },
  processFeature: { error: "", loading: false },
  allFeatures: {
    data: [],
    error: "",
    loading: false,
    pagination: {
      next: 2,
      total: 0,
      prevPage: 0,
      lastPage: 0,
      pageSize: 10,
      hasNext: true,
      currentPage: 1,
      hasPrevious: false
    }
  }
};

export const FeatureReducer = createSlice({
  initialState,
  name: "feature",
  reducers: {
    getAllFeaturesLoading: (state) => {
      state.allFeatures.loading = true;
    },
    getAllFeaturesSuccess: (
      state,
      action: PayloadAction<{
        data: FeatureData[];
        pagination: PaginationState;
      }>
    ) => {
      state.allFeatures.loading = false;
      state.allFeatures.data = action.payload.data;
      state.allFeatures.pagination = action.payload.pagination;
    },
    getAllFeaturesFailed: (state, action: PayloadAction<string>) => {
      state.allFeatures.loading = false;
      state.allFeatures.error = action.payload;
    },
    processFeatureLoading: (state) => {
      state.processFeature.loading = true;
    },
    processFeatureSuccess: (state) => {
      state.processFeature.loading = false;
    },
    processFeatureFailed: (state, action: PayloadAction<string>) => {
      state.processFeature.loading = false;
      state.processFeature.error = action.payload;
    },
    addFeatureLoading: (state) => {
      state.addFeature.loading = true;
    },
    addFeatureSuccess: (state) => {
      state.addFeature.loading = false;
    },
    addFeatureFailed: (state, action: PayloadAction<string>) => {
      state.addFeature.loading = false;
      state.addFeature.error = action.payload;
    }
  }
});

export const actions = FeatureReducer.actions;
export default FeatureReducer.reducer;
