import {
  Tab as ChakraTab,
  TabPanelProps,
  TabProps,
  TabPanel as ChakraTabPanel,
  Box,
  BoxProps,
  Link as ChakraLink
} from "@chakra-ui/react";
import { Link, useSearchParams } from "react-router-dom";

export const Tab = (props: TabProps) => (
  <ChakraTab
    _selected={{
      fontSize: 16,
      fontWeight: 600,
      borderBottomWidth: 2,
      borderStyle: "solid",
      borderColor: "primary.500",
      backgroundColor: "transparent !important"
    }}
    {...props}
  />
);

export const TabPanel = (props: TabPanelProps) => (
  <ChakraTabPanel p={0} {...props} />
);

// TabLink

type Props = { type: string } & BoxProps;

export const TabLink = ({ children, type, ...props }: Props): JSX.Element => {
  let [searchParams] = useSearchParams();
  let isActive = searchParams.get("type") === type;

  return (
    <ChakraLink
      as={Link}
      to={`?type=${type}`}
      w="fit-content"
      textDecor={"none"}
      textTransform="none"
      _hover={{
        textDecor: "none",
        textColor: (theme) => theme.colors.primary[600]
      }}
    >
      <Box
        width="100%"
        flexGrow={1}
        height="35px"
        flexBasis="50%"
        alignItems="center"
        px={4}
        display="inline-flex"
        justifyContent="center"
        color={isActive ? "primary.600" : "gray.600"}
        fontSize={16}
        fontWeight={600}
        borderBottomWidth={2}
        borderStyle="solid"
        borderColor="primary.500"
        backgroundColor="transparent !important"
        borderBottom={isActive ? "primary.600" : "transparent"}
        {...props}
      >
        {children}
      </Box>
    </ChakraLink>
  );
};
