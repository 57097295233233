import {
  getBusinessVirtualCards,
  updateBusinessVirtualCards
} from "data/store";
import { IUpdateVirtualCardsBody } from "data/models/user";
import { useAppDispatch, useAppSelector } from "./useReduxState";

export const useVirtualCard = () => {
  const dispatch = useAppDispatch();

  const [virtualCards, updateVirtualCard] = useAppSelector((state) => [
    state.users.businessVirtualCards,
    state.users.updateBusinessVirtualCard
  ]);

  const dispatchAllVirtualCards = async (id: string) => {
    dispatch(getBusinessVirtualCards(id));
  };

  const dispatchUpdateVirtualCard = async (body: IUpdateVirtualCardsBody) => {
    return await dispatch(updateBusinessVirtualCards(body));
  };

  return {
    virtualCards,
    updateVirtualCard,
    dispatchAllVirtualCards,
    dispatchUpdateVirtualCard
  };
};
