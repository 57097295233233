import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import { IAddFeature } from "data/models/feature-management";
import { actions } from "../reducers/feature-management.reducer";
import featureService from "data/services/feature-management.service";

export const getAllFeatures = (params: { page: number; perpage: number }) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllFeaturesLoading());
      const res = await featureService.getAllFeatures(params);
      dispatch(actions.getAllFeaturesSuccess(res.data));
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.getAllFeaturesFailed(msg));
      toast.error(msg);
    }
  };
};

export const processFeature = (
  id: number,
  body: { active: boolean },
  callback?: () => void
) => {
  return async (dispatch: Dispatch) => {
    const toastId = toast.loading(
      `${body.active ? "activating" : "deactivating"}`
    );
    try {
      dispatch(actions.processFeatureLoading());
      const res = await featureService.processFeature(id, body);
      dispatch(actions.processFeatureSuccess());
      toast.success(res.data.message, { id: toastId });
      if (callback) callback();
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.processFeatureFailed(msg));
      toast.error(msg, { id: toastId });
    }
  };
};

export const addFeature = (body: IAddFeature, callback?: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.addFeatureLoading());
      const res = await featureService.addFeature(body);
      dispatch(actions.addFeatureSuccess());
      toast.success(res.data.message);
      if (callback) callback();
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      dispatch(actions.addFeatureFailed(msg));
      toast.error(msg);
    }
  };
};
