import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "data/models/auth";
import ExpirySession, { tokenKey } from "data/utils/expiry-session";

const twoFactorInit = {
  step: 0,
  state: {
    otpPathUrl: "",
    secret: ""
  },
  email: ""
};

const initialState: AuthState = {
  isLoggedIn: !!ExpirySession.get(tokenKey),
  isRegistered: false,
  loading: false,
  errorMsg: "",
  error: false,
  twoFactor: twoFactorInit
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loadingBegin: (state: AuthState) => {
      state.loading = true;
    },
    loadingStop: (state: AuthState) => {
      state.loading = false;
    },
    error: (state: AuthState) => {
      state.loading = false;
      state.error = true;
    },
    loginSuccess: (state: AuthState, action: PayloadAction<boolean>) => {
      state.loading = false;
      state.isLoggedIn = action.payload;
      state.error = false;
      state.twoFactor = twoFactorInit;
    },
    logoutBegin: (state: AuthState) => {
      state.loading = true;
    },
    logoutSuccess: (state: AuthState) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.error = false;
    },
    set2FA: (state, action: any) => {
      state.twoFactor.state.otpPathUrl = action.payload;
    },
    settwoFAStep: (state, action: PayloadAction<number>) => {
      state.twoFactor.step = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.twoFactor.email = action.payload;
    },
    success: (state: AuthState) => {
      state.loading = false;
    }
  }
});

export const {
  loadingBegin,
  error,
  loginSuccess,
  logoutBegin,
  logoutSuccess,
  loadingStop,
  set2FA,
  settwoFAStep,
  setEmail,
  success
} = AuthSlice.actions;

export default AuthSlice.reducer;
