import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import AuthGuard from "./auth-guard";
import AuthLayout from "views/layout/auth-layout";
import Login from "views/pages/authentication/login";
import DashboardLayout from "views/layout/dashboard-layout";
import { TransactionPage } from "views/pages/transactions";
import NotFound from "views/pages/unauthorized/404";
import Unauthorized from "views/pages/unauthorized/forbidden";

const UpdatePassword = lazy(
  () => import("views/pages/authentication/update-password")
);

const Dashboard = lazy(() => import("views/pages/dashboard"));

const Authorization = lazy(() => import("views/pages/settings/authorization"));
const Settings2fa = lazy(() => import("views/pages/settings/2fa"));
const Rates = lazy(() => import("views/pages/settings/rates"));
const Config = lazy(() => import("views/pages/settings/config"));
const Charges = lazy(() => import("views/pages/settings/charges"));
const Limits = lazy(() => import("views/pages/settings/limits"));

const Users = lazy(() => import("views/pages/manage-users"));
const AuditTrail = lazy(() => import("views/pages/audit-trail"));
const AppConfig = lazy(() => import("views/pages/app-config"));
const SingleBusiness = lazy(
  () => import("views/pages/manage-users/single-business")
);
const Withdrawals = lazy(() => import("views/pages/withdrawals"));
const ThirdPartyDeposits = lazy(
  () => import("views/pages/third-party-deposits")
);
const Refunds = lazy(() => import("views/pages/refunds"));

const routes: RouteObject[] = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        element: <Dashboard />,
        path: "/"
      },
      {
        element: <TransactionPage />,
        path: "/transactions"
      },
      {
        element: <Withdrawals />,
        path: "withdrawals"
      },
      {
        element: <ThirdPartyDeposits />,
        path: "third-party-deposits"
      },
      {
        element: <Refunds />,
        path: "refunds"
      },
      {
        element: <AppConfig />,
        path: "app-config"
      },
      {
        element: <AuditTrail />,
        path: "audit-trail"
      },
      {
        element: <Authorization />,
        path: "authorization"
      },
      {
        element: <Config />,
        path: "configs"
      },
      {
        element: <Rates />,
        path: "rates"
      },
      { element: <Charges />, path: "charges" },
      { element: <Limits />, path: "limits" },
      { element: <Users />, path: "customers" },
      { element: <Settings2fa />, path: "2fa" },
      {
        element: <SingleBusiness />,
        path: "/customers/:id"
      },
      { element: <Unauthorized />, path: "/forbidden" }
    ]
  },
  {
    element: <AuthLayout />,
    children: [
      {
        element: <Login />,
        path: "/auth/login"
      },
      {
        element: <UpdatePassword />,
        path: "/admin/set-password"
      }
    ]
  },
  {
    element: <NotFound />,
    path: "*"
  }
];

export default routes;
