import axios, { AxiosRequestHeaders } from "axios";

import config from "data/config";
import ExpirySession, { tokenKey } from "./utils/expiry-session";

const baseURL = config.server;

const instance = axios.create({
  baseURL: baseURL
});

export const authHeader = async (headers: AxiosRequestHeaders | undefined) => {
  const accessToken = await ExpirySession.get(tokenKey);
  return {
    ...headers,
    Authorization: `Bearer ${accessToken}`
  };
};

instance.interceptors.request.use(async (config) => {
  const requestConfig = { ...config };
  const { headers } = config;
  requestConfig.headers = await authHeader(headers);

  return requestConfig;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    // const { response } = error;

    // if (response && response.status === 401) {
    //   ExpirySession.clear();
    //   return window.location.reload();
    //   // return (window.location.href = "/auth/login");
    // }
    // if (response && response.status === 403) {
    //   return (window.location.href = "/forbidden");
    // }

    return Promise.reject(error);
  }
);

export default instance;

export const authInstance = axios.create({
  baseURL: baseURL
});

// Payday Auth Setup

const baseURLPdAuth = config.pdAuthServer;
export const instancePaydayAuth = axios.create({
  baseURL: baseURLPdAuth
});

instancePaydayAuth.interceptors.request.use(async (config) => {
  const requestConfig = { ...config };
  const { headers } = config;
  requestConfig.headers = await authHeader(headers);

  return requestConfig;
});

instancePaydayAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    // const { response } = error;

    // // if (response && response.status === 401) {
    // //   ExpirySession.clear();
    // //   return window.location.reload();
    // //   // return (window.location.href = "/auth/login");
    // // }
    // if (response && response.status === 403) {
    //   return (window.location.href = "/forbidden");
    // }

    return Promise.reject(error);
  }
);

// Payday Setup

const baseURLPd = config.pdServer;

export const instancePayday = axios.create({
  baseURL: baseURLPd
});

instancePayday.interceptors.request.use(async (config) => {
  const requestConfig = { ...config };
  const { headers } = config;
  requestConfig.headers = await authHeader(headers);

  return requestConfig;
});

instancePayday.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;

    if (response && response.status === 401) {
      ExpirySession.clear();
      return window.location.reload();
    }

    // if (response && response.status === 403) {
    //   return (window.location.href = "/forbidden");
    // }

    return Promise.reject(error);
  }
);
