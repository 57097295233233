import { AxiosResponse } from "axios";
import { instancePayday as instance } from "data/axios-setup";
import { FeatureData, PaginationState } from "data/models/feature-management";

class FeatureService {
  async getAllFeatures(params: {
    page: number;
    perpage: number;
  }): Promise<
    AxiosResponse<{ data: FeatureData[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/feature-management`, { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async addFeature(body: {
    feature: string;
    active: boolean;
  }): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(`/admin/feature-management`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async processFeature(
    id: number,
    body: { active: boolean }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/feature-management/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new FeatureService();
