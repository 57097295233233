import {
  Stack,
  Button,
  HStack,
  PinInput,
  PinInputField
} from "@chakra-ui/react";
import { login2FA, login2FAAuth } from "data/store";
import { AuthLocationState } from "data/models/base";
import { useCallback, useEffect, useState, FC } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useLocation, useNavigate } from "react-router-dom";

export const TwoFACode: FC<{
  email: string;
  use2FA: any;
  loginCode: string;
}> = ({ email, use2FA, loginCode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [pin, setPin] = useState("");
  const isLoading = useAppSelector((state) => state.auth.loading);
  const from = (location.state as AuthLocationState)?.from?.pathname || "/";

  const submitPinHandler = useCallback(
    async (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (pin.length < 6) return;

      if (use2FA) {
        dispatch(
          login2FAAuth(navigate, from, {
            email,
            auth2FaToken: pin,
            code: loginCode
          })
        );
      } else {
        dispatch(login2FA(navigate, from, { email, code: pin }));
      }
    },
    [pin]
  );

  useEffect(() => {
    if (pin.length < 6) return;
    if (use2FA) {
      dispatch(
        login2FAAuth(navigate, from, {
          email,
          auth2FaToken: pin,
          code: loginCode
        })
      );
    } else {
      dispatch(login2FA(navigate, from, { email, code: pin }));
    }
  }, [pin]);
  // const [loading, setLoading] = useState(false);
  // const resendOTP = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await walletService.lockWallet(id, state);
  //     toast.success(res.data.message);
  //     dispatchSingleBusiness(id);
  //     onClose();
  //     setLoading(false);
  //   } catch (err: any) {
  //     const error = err as AxiosError<{ message: string }>;
  //     const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
  //     toast.error(msg);
  //     setLoading(false);
  //   }
  // };

  return (
    <Stack alignItems={"center"}>
      <p className="text-gray-900 text-heading100 mdx:text-heading300">
        Welcome Back!
      </p>
      <p className="mt-1 text-gray-700">Enter your 6 digit code below</p>
      <HStack py="10">
        <PinInput value={pin} onChange={(value) => setPin(value)}>
          <PinInputField borderColor={"primary.500"} />
          <PinInputField borderColor={"primary.500"} />
          <PinInputField borderColor={"primary.500"} />
          <PinInputField borderColor={"primary.500"} />
          <PinInputField borderColor={"primary.500"} />
          <PinInputField borderColor={"primary.500"} />
        </PinInput>
      </HStack>
      <div className="w-full mt-8 mdx:mt-12">
        <Button
          isDisabled={pin.length < 6}
          isLoading={isLoading}
          className="mdx:p-6 bg-black"
          textColor="white"
          type="submit"
          bgColor="#000"
          w="full"
          _hover={{ bgColor: "#000" }}
          onClick={submitPinHandler}
        >
          <div className="flex items-center">
            <p>Submit</p>
          </div>
        </Button>
      </div>
      {/* {!use2FA && (
        <p className="mt-1 text-gray-700 text-cecnter" onClick={resendOTP}>
          Resend OTP
        </p>
      )} */}
    </Stack>
  );
};
