import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPaginationPayday } from "data/models/base";
import {
  AuthorizationData,
  ConfigData,
  ChargesFilter,
  ChargesResponse,
  ISettingsState,
  RatesResponse,
  LimitsResponse
} from "data/models/settings";

const pagination = {
  nextPage: 0,
  total: 0,
  previousPage: 0,
  pageCount: 10,
  page: 1,
  limit: 10
};

const initialState: ISettingsState = {
  filter: {},
  UserAvatarUpload: {
    loading: false,
    error: ""
  },
  getAuthorization: {
    data: [],
    error: "",
    pagination,
    loading: false
  },
  getConfig: {
    data: [],
    error: "",
    pagination,
    loading: false
  },
  getAllCharges: {
    data: [],
    error: "",
    pagination,
    loading: false
  },
  getAllLimits: {
    data: [],
    error: "",
    pagination,
    loading: false
  },
  getAllRates: {
    data: [],
    error: "",
    pagination,
    loading: false
  }
};

const SettingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    UserAvatarUploadBegin: (state) => {
      state.UserAvatarUpload.loading = true;
    },
    UserAvatarUploadSuccess: (state) => {
      state.UserAvatarUpload.loading = false;
    },
    UserAvatarUploadFailed: (state, action: PayloadAction<string>) => {
      state.UserAvatarUpload.loading = false;
      state.UserAvatarUpload.error = action.payload;
    },
    getAuthorizationLoading: (state) => {
      state.getAuthorization.error = "";
      state.getAuthorization.loading = true;
    },

    getAuthorizationFailed: (state, action: PayloadAction<string>) => {
      state.getAuthorization.loading = false;
      state.getAuthorization.error = action.payload;
    },

    getAuthorizationSuccesss: (
      state,
      action: PayloadAction<{
        data: AuthorizationData[];
        pagination: IPaginationPayday;
      }>
    ) => {
      state.getAuthorization.error = "";
      state.getAuthorization.loading = false;
      state.getAuthorization.data = action.payload.data;
      state.getAuthorization.pagination = action.payload.pagination;
    },
    getConfigLoading: (state) => {
      state.getConfig.error = "";
      state.getConfig.loading = true;
    },

    getConfigFailed: (state, action: PayloadAction<string>) => {
      state.getConfig.loading = false;
      state.getConfig.error = action.payload;
    },

    getConfigSuccesss: (
      state,
      action: PayloadAction<{
        data: ConfigData[];
        pagination: IPaginationPayday;
      }>
    ) => {
      state.getConfig.error = "";
      state.getConfig.loading = false;
      state.getConfig.data = action.payload.data;
      state.getConfig.pagination = action.payload.pagination;
    },
    getAllChargeslLoading: (state) => {
      state.getAllCharges.error = "";
      state.getAllCharges.loading = true;
    },

    getAllChargesFailed: (state, action: PayloadAction<string>) => {
      state.getAllCharges.loading = false;
      state.getAllCharges.error = action.payload;
    },

    getAllChargesSuccesss: (
      state,
      action: PayloadAction<{
        data: ChargesResponse[];
        pagination: IPaginationPayday;
      }>
    ) => {
      state.getAllCharges.error = "";
      state.getAllCharges.loading = false;
      state.getAllCharges.data = action.payload.data;
      state.getAllCharges.pagination = action.payload.pagination;
    },
    setChargesFilter: (
      state,
      action: PayloadAction<{ data: Partial<ChargesFilter> }>
    ) => {
      state.filter = { ...action.payload.data };
    },
    getAllRateslLoading: (state) => {
      state.getAllRates.error = "";
      state.getAllRates.loading = true;
    },

    getAllRatesFailed: (state, action: PayloadAction<string>) => {
      state.getAllRates.loading = false;
      state.getAllRates.error = action.payload;
    },

    getAllRatesSuccesss: (
      state,
      action: PayloadAction<{
        data: RatesResponse[];
        pagination: IPaginationPayday;
      }>
    ) => {
      state.getAllRates.error = "";
      state.getAllRates.loading = false;
      state.getAllRates.data = action.payload.data;
      state.getAllRates.pagination = action.payload.pagination;
    },

    getAllLimitslLoading: (state) => {
      state.getAllLimits.error = "";
      state.getAllLimits.loading = true;
    },

    getAllLimitsFailed: (state, action: PayloadAction<string>) => {
      state.getAllLimits.loading = false;
      state.getAllLimits.error = action.payload;
    },

    getAllLimitsSuccesss: (
      state,
      action: PayloadAction<{
        data: LimitsResponse[];
        pagination: IPaginationPayday;
      }>
    ) => {
      state.getAllLimits.error = "";
      state.getAllLimits.loading = false;
      state.getAllLimits.data = action.payload.data;
      state.getAllLimits.pagination = action.payload.pagination;
    }
  }
});

export const actions = SettingsSlice.actions;

export default SettingsSlice.reducer;
