import {
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps
} from "@chakra-ui/react";

export type FormLabelProps = ChakraFormLabelProps & {};

export const FormLabel = ({
  children,
  fontWeight = 400,
  fontSize = "14px",
  color = "#667085",
  lineHeight = "24px",
  letterSpacing = "1px",
  display = "inline-block",
  marginBottom = "0.5 !important",
  ...props
}: FormLabelProps) => {
  return (
    <ChakraFormLabel
      margin={0}
      color={color}
      display={display}
      fontSize={fontSize}
      children={children}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      marginBottom={marginBottom}
      letterSpacing={letterSpacing}
      {...props}
    />
  );
};
