import { AxiosResponse } from "axios";
const errors = new Map<string, string>([
  ["Unauthorized", "Unauthorized Credentials"]
]);

export type DEFAULT_STATUS_TYPE = "idle" | "loading" | "succeeded" | "failed";

export const DEFAULT_ERROR_MESSAGE =
  "An error occurred, please try again later";

export default function mapCodeToErrorText(code?: string): string {
  if (!code) return DEFAULT_ERROR_MESSAGE;
  const text = errors.get(code);
  return text || DEFAULT_ERROR_MESSAGE;
}

export const onResponseSuccess = (res: AxiosResponse<any>, cb: () => void) => {
  if (res.status === 200 || 201 || 202) {
    cb();
  }
};
