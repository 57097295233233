import { Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import toast from "react-hot-toast";
import { actions } from "../reducers/withdrawals.reducer";
import transactionsService from "data/services/transactions.service";

export const getAllWithdrawalsV2 = (params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllWithdrawalsBegin());
      const res = await transactionsService.getAllWithdrawalsPayDay(params);
      dispatch(actions.getAllWithdrawalsSuceess(res.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllWithdrawalsFailed(msg));
    }
  };
};

export const setStatusParam = (status: string) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setWithdrawalStatus(status));
  };
};

export const getAllThirdPartyDeposits = (params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllThirdPartyDepositsBegin());
      const res = await transactionsService.getAllThirdPartyDepositsPayDay(
        params
      );
      dispatch(actions.getAllThirdPartyDepositsSuceess(res.data.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllThirdPartyDepositsFailed(msg));
    }
  };
};

export const setStatusParamThirdPartyDeposits = (status: string) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setThirdPartyDeposits(status));
  };
};

export const getAllRefunds = (params?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllRefundsBegin());
      const res = await transactionsService.getAllRefundsPayday(params);
      dispatch(actions.getAllRefundsSuceess(res.data.data));
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllRefundsFailed(msg));
    }
  };
};

export const setStatusParamRefunds = (status: string) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setRefundStatus(status));
  };
};
