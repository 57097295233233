/* AWS S3 config options */
/* Highly recommended to declare the config object in an external file import it when needed */

import { getEnv } from ".";

/* s3Config.ts */

export const s3Config = {
  bucketName: `${getEnv("REACT_APP_DO_BUCKET_NAME", { optional: true })}`,
  region: `${getEnv("REACT_APP_DO_SPACES_REGION", { optional: true })}`,
  accessKeyId: `${getEnv("REACT_APP_DO_SPACES_ACCESS_KEY", {
    optional: true
  })}`,
  secretAccessKey: `${getEnv("REACT_APP_DO_SPACES_SECRET_ACCESS_KEY", {
    optional: true
  })}`,
  s3Url: `${getEnv("REACT_APP_DO_SPACES_URL", {
    optional: true
  })}` /* Optional */
};

/* End of s3Config.ts */
