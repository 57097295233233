import { useAppSelector } from "app/hooks";
import { Navigate, useLocation } from "react-router";
import ExpirySession, { tokenKey } from "data/utils/expiry-session";

const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const [isLoggedIn] = useAppSelector((state) => [state.auth.isLoggedIn]);
  ExpirySession.get(tokenKey);

  if (!isLoggedIn) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthGuard;
