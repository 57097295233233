import {
  IMeta,
  Filter,
  ITransaction,
  PaginationState,
  ITransactionError,
  ITransactionState,
  TTransactionLoading,
  ICardTransaction,
  NewPaginationState
} from "../../models/transaction";
import { DEFAULT_STATUS_TYPE } from "data/error-mapping";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const pagination = {
  next: 0,
  total: 0,
  limit: 0,
  page: 0,
  prevPage: 0,
  nextPage: 0,
  lastPage: 0,
  pageCount: 1,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  previousPage: 0,
  hasPrevious: false
};

export const filterInitialState: Filter = {
  dateType: "day",
  from: undefined,
  date: undefined,
  status: undefined,
  dateFormat: "dd/MM/yyyy",
  showMonthYearPicker: false
};

const initialState: ITransactionState = {
  error: null,
  transaction: null,
  transactionById: null,
  selectedTransactionId: null,
  airtimeData: { data: [], pagination },
  conversions: { data: [], pagination },
  transactions: { data: [], pagination },
  withdrawal: { data: [], pagination },
  withdrawals: { data: [], pagination },
  cardTransactions: { data: [], pagination },
  sendMoney: { data: [], pagination },
  receentTransactions: { data: [], pagination },
  filter: filterInitialState,
  getTransactionCallers: { data: [], error: "", isLoading: false },

  getAllTransactions: { data: [], error: "", isLoading: false, pagination },
  statement: {
    card: [],
    ticket: [],
    funding: [],
    withdrawal: [],
    paySomeone: [],
    conversion: [],
    airtimeData: []
  },
  status: {
    getTransactions: "idle",
    getAllWithdrawals: "idle",
    getTransactionById: "idle",
    getCardTransactions: "idle",
    getSingleTransaction: "idle",
    getRecentTransactions: "idle",
    getConversionTransactions: "idle",
    getAirtimeDataTransactions: "idle",
    getTransactionStatement: "idle"
  },
  transactionSummary: {
    error: "",
    loading: false,
    data: null
  },
  changingStatus: false
};

export const TransactionSlice = createSlice({
  initialState,
  name: "transactions",
  reducers: {
    getTransactionCallersBegin: (state: ITransactionState) => {
      state.getTransactionCallers.error = "";
      state.getTransactionCallers.isLoading = true;
    },

    getTransactionCallersEnd: (
      state: ITransactionState,
      action: PayloadAction<string[]>
    ) => {
      state.getTransactionCallers.isLoading = false;
      state.getTransactionCallers.data = action.payload;
    },

    getAllTransactionsBegin: (state: ITransactionState) => {
      state.getAllTransactions.error = "";
      state.getAllTransactions.isLoading = true;
    },

    getAllTransactionsFailed: (
      state: ITransactionState,
      action: PayloadAction<string>
    ) => {
      state.getAllTransactions.isLoading = false;
      state.getAllTransactions.error = action.payload;
    },

    getAllTransactionsSuccess: (
      state: ITransactionState,
      action: PayloadAction<{ data: ITransaction[]; meta: IMeta }>
    ) => {
      state.getAllTransactions.error = "";
      state.getAllTransactions.isLoading = false;
      state.getAllTransactions.data = action.payload.data;
      state.getAllTransactions.pagination = action.payload.meta;
    },

    loading: (
      state: ITransactionState,
      action: PayloadAction<TTransactionLoading>
    ) => {
      state.status[action.payload] = "loading";
    },
    error: (
      state: ITransactionState,
      action: PayloadAction<ITransactionError>
    ) => {
      state.error = action.payload.msg;
      state.status[action.payload.for] = "failed";
    },
    setTransactions: (
      state: ITransactionState,
      action: PayloadAction<{
        data: ITransaction[];
        meta: NewPaginationState;
      }>
    ) => {
      state.status.getTransactions = "succeeded";
      state.transactions.data = action.payload.data;
      state.transactions.pagination = action.payload.meta;
    },
    setAirtimeDataTransactions: (
      state: ITransactionState,
      action: PayloadAction<{
        data: ITransaction[];
        pagination: PaginationState;
      }>
    ) => {
      state.airtimeData.data = action.payload.data;
      state.status.getAirtimeDataTransactions = "succeeded";
      state.airtimeData.pagination = action.payload.pagination;
    },
    setConverstionTransactions: (
      state: ITransactionState,
      action: PayloadAction<{
        data: any[];
        pagination: PaginationState;
      }>
    ) => {
      state.conversions.data = action.payload.data;
      state.status.getConversionTransactions = "succeeded";
      state.conversions.pagination = action.payload.pagination;
    },
    setWithdrawalTransactionsInfo: (
      state: ITransactionState,
      action: PayloadAction<{ data: any[]; pagination: PaginationState }>
    ) => {
      state.withdrawal.data = action.payload.data;
      state.status.getAllWithdrawals = "succeeded";
      state.withdrawal.pagination = action.payload.pagination;
    },

    setWithdrawalSendMoneyInfo: (
      state: ITransactionState,
      action: PayloadAction<{ data: any[]; pagination: PaginationState }>
    ) => {
      state.sendMoney.data = action.payload.data;
      state.status.getAllWithdrawals = "succeeded";
      state.sendMoney.pagination = action.payload.pagination;
    },

    setWithdrawalsInfo: (
      state: ITransactionState,
      action: PayloadAction<{ data: any[]; pagination: PaginationState }>
    ) => {
      state.withdrawals.data = action.payload.data;
      state.status.getAllWithdrawals = "succeeded";
      state.withdrawals.pagination = action.payload.pagination;
    },

    changingWitdrawalStatus: (state: ITransactionState) => {
      state.changingStatus = true;
    },

    withdrawalStatusChanged: (state: ITransactionState) => {
      state.changingStatus = false;
    },

    setCardTransactions: (
      state: ITransactionState,
      action: PayloadAction<{
        data: ICardTransaction[];
        pagination: PaginationState;
      }>
    ) => {
      state.cardTransactions.data = action.payload.data;
      state.status.getCardTransactions = "succeeded";
      state.cardTransactions.pagination = action.payload.pagination;
    },

    setTransactionStatementStatus: (
      state: ITransactionState,
      action: PayloadAction<DEFAULT_STATUS_TYPE>
    ) => {
      state.status.getTransactionStatement = action.payload;
    },

    setTransactionStatement: (
      state: ITransactionState,
      action: PayloadAction<{
        data: any[];
        type:
          | "card"
          | "ticket"
          | "withdrawal"
          | "paySomeone"
          | "funding"
          | "conversion"
          | "airtimeData";
      }>
    ) => {
      state.status.getTransactionStatement = "succeeded";
      state.statement[action.payload.type] = action.payload.data;
    },

    setTransactionFilter: (
      state: ITransactionState,
      action: PayloadAction<{ data: Partial<Filter> }>
    ) => {
      state.filter = { ...action.payload.data };
    }
  }
});

export const actions = TransactionSlice.actions;
export default TransactionSlice.reducer;
