import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPagination } from "data/models/base";
import { ILienResData, ILienState } from "data/models/lien";

const pagination: IPagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: ILienState = {
  allLiens: {
    data: [],
    pagination,
    loading: false,
    error: ""
  }
};

const LienSlice = createSlice({
  name: "lien",
  initialState,
  reducers: {
    getAllLienBegin: (state) => {
      state.allLiens.loading = true;
      state.allLiens.error = "";
    },
    getAllLienSuceess: (
      state,
      action: PayloadAction<{
        data: ILienResData[];
        pagination: IPagination;
      }>
    ) => {
      state.allLiens.data = action.payload.data;
      state.allLiens.pagination = action.payload.pagination;
      state.allLiens.loading = false;
      state.allLiens.error = "";
    },
    getAllLienFailed: (state, action: PayloadAction<string>) => {
      state.allLiens.loading = false;
      state.allLiens.error = action.payload;
    }
  }
});

export const actions = LienSlice.actions;

export default LienSlice.reducer;
