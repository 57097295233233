import { Outlet } from "react-router-dom";

const Content = (): JSX.Element => {
  return (
    <main className=" overflow-x-auto scroll-smooth min-h-[calc(100vh-5rem)]">
      <Outlet />
    </main>
  );
};

export default Content;
