import { AxiosResponse } from "axios";
import instance, { instancePayday } from "data/axios-setup";
import { IPagination } from "data/models/base";
import {
  IProcessBusinessReqBody,
  IBusinessRequestData,
  IDisableBusinessReqBody,
  IGetAllBusinessParams,
  IGetAllUsersParams,
  ISingleBusinessRequestData,
  IUsersRequestData,
  IBusinessTxSummary,
  IBusinessMobileMoneyWalletParams,
  IBusinessMobileMoneyWallet,
  IBusinessBank,
  IBusinessDocUploadReqData,
  IBusinessVirtualAccount,
  IGetAllBusinessKycParams,
  IBusinessVirtualCard,
  IUpdateVirtualCardsBody,
  ISingleUserKyc,
  ISetUserStatusBody,
  ICardTransactions
} from "data/models/user";

class UserService {
  async getAllBusiness(
    params: IGetAllBusinessParams
  ): Promise<
    AxiosResponse<{ data: IBusinessRequestData[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/business", { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllUsers(
    params: IGetAllUsersParams
  ): Promise<
    AxiosResponse<{ data: IUsersRequestData[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/user", { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getUsersByEmail(
    params: IGetAllUsersParams
  ): Promise<
    AxiosResponse<{ data: IUsersRequestData[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/user/search-by-email", {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleBusiness(
    id: string
  ): Promise<AxiosResponse<{ data: ISingleBusinessRequestData }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get(`/users/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSingleUserKyc(
    id: string
  ): Promise<AxiosResponse<{ data: ISingleUserKyc }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get(
          `/users/verifications/${id}?id=${id}`
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async processBusiness(
    id: string,
    body: IProcessBusinessReqBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(`/users/${id}/kyc-status`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async disableBusiness(
    id: number,
    body: IDisableBusinessReqBody
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/business/disable/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async blockUser(
    id: number,
    reason: string
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/user/disable/${id}`, { reason });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async activateUser(id: number): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/user/enable/${id}`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getBlockedkedBusiness(): Promise<
    AxiosResponse<{ data: IBusinessRequestData[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/business", {
          params: { active: false }
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getActiveBusiness(): Promise<
    AxiosResponse<{ data: IBusinessRequestData[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/business", {
          params: { active: true }
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getTransactionSummary(
    id: string | number
  ): Promise<AxiosResponse<{ data: IBusinessTxSummary }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/transaction/summary/${id}`, {
          params: { active: true }
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getBusinessMobileMoneyWallets(
    params: IBusinessMobileMoneyWalletParams
  ): Promise<
    AxiosResponse<{
      data: IBusinessMobileMoneyWallet[];
      pagination: IPagination;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/wallet/mobile-money", {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getBanks(params: IBusinessMobileMoneyWalletParams): Promise<
    AxiosResponse<{
      data: IBusinessBank[];
      pagination: IPagination;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/banks", {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getBusinessVirtualAccountss(id: string): Promise<
    AxiosResponse<{
      data: IBusinessVirtualAccount;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get(
          `/users/${id}/bank-accounts?id=${id}`
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getBusinessVirtualCards(id: string): Promise<
    AxiosResponse<{
      data: IBusinessVirtualCard;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get(
          `/users/${id}/virtual-cards?id=${id}`
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async updateBusinessVirtualCards(body: IUpdateVirtualCardsBody): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.patch("/admin/virtual-cards", body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async uploadBusinessDocuments(
    id: number,
    body: IBusinessDocUploadReqData,
    onUploadProgress?: (progress: number) => void
  ): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(
          `/admin/business/upload/documents/${id}`,
          body,
          {
            onUploadProgress: (progressEvent) => {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 80;
              onUploadProgress && onUploadProgress(progress);
            }
          }
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllBusinessKyc(
    params: IGetAllBusinessKycParams
  ): Promise<
    AxiosResponse<{ data: { users: any[]; pagination: IPagination } }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get("/users", { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllPaydayUsers(
    params: Record<string, any>
  ): Promise<
    AxiosResponse<{ data: { users: any[]; pagination: IPagination } }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get("/users", { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async searchAllUsers(
    params: Record<string, any>
  ): Promise<
    AxiosResponse<{ data: { users: any[]; pagination: IPagination } }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get("/users/search", { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async setUserStatus(
    id: string,
    body: ISetUserStatusBody
  ): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(`/user/status/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getUserStatuses(): Promise<
    AxiosResponse<{ data: string[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get(`/available/status`);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async fixMultipleBVNAccounts(body: { userId: string }): Promise<
    AxiosResponse<{
      message: string;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(
          `/fix-multiple-bvn-account`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getCardTransactions(payload: {
    cardId: string;
    userId: string;
  }): Promise<AxiosResponse<ICardTransactions>> {
    return new Promise(async (resolve, reject) => {
      const { cardId, userId } = payload;
      try {
        const res = await instancePayday.get(
          `/users/${userId}/virtual-cards/${cardId}/transactions`
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new UserService();
