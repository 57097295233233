import {
  Tr,
  Td,
  Box,
  Text,
  Icon,
  Input,
  Stack,
  Thead,
  Table,
  Center,
  Button,
  InputGroup,
  TableContainer,
  InputLeftElement,
  Link
} from "@chakra-ui/react";
import React from "react";
import NoData from "views/components/no-data";
import { SearchNormal } from "iconsax-react";
import { usePagination } from "@ajna/pagination";
import { useSearchParams } from "react-router-dom";
import { Tbody, Th } from "views/components/table";
import { getTransactionTypeColor } from "data/utils";
import { useDebounce } from "app/hooks/use-debounce";
import { LoadingTable } from "views/components/loading";
import { formatter } from "data/utils/number-formatter";
import getSymbolFromCurrency from "currency-symbol-map";
import { useAppDispatch, useTransactions } from "app/hooks";
import { usePayDayTransaction } from "app/hooks/use-transactions";
import { ChangeraTransactionType } from "data/models/transaction";
import { getAllTransactions, setTransactionFilter } from "data/store";
import { filterInitialState } from "data/store/reducers/transactions.reducer";
import PaginationWrapper from "views/components/pagination-wrapper/PaginationWrapper";
import Select from "views/components/select/newSelect";

type Props = {
  searchString: string;
  setSearchString: any;
  subCallers: { [x: string]: string[] };
};

const headers = ["#User ID", "Amount", "Channel", "Description", "Date", ""];

const TransactionWithdrawal = ({
  subCallers,
  searchString,
  setSearchString
}: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const caller = searchParams.get("type")!;
  const { transactions, dispatchGetAllTransactions } = usePayDayTransaction();

  const allFilters = subCallers?.[caller] || [];
  const [filter, setFilter] = React.useState(allFilters?.[0] || "");

  const {
    pages,
    offset,
    pageSize,
    pagesCount,
    isDisabled,
    currentPage,
    setPageSize,
    setCurrentPage
  } = usePagination({
    limits: { inner: 2, outer: 1 },
    total: transactions.pagination?.total || 1,
    initialState: { currentPage: 1, pageSize: 10 }
  });

  const handlePageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
    setTimeout(() => {
      window.scrollTo({ top: 10, behavior: "smooth" });
    }, 500);
  };

  const debouncedSearchString = useDebounce(searchString, 1000);

  // Transaction Details
  const { onOpenTransactionDetails, TransactionDetailsModal } =
    useTransactions();

  const txCaller =
    (filter && allFilters?.includes(filter) ? filter : allFilters?.[0]) ||
    allFilters?.[0] ||
    caller;

  React.useEffect(() => {
    dispatchGetAllTransactions({ caller: txCaller });
    dispatch(setTransactionFilter(filterInitialState));
  }, []);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [txCaller, debouncedSearchString]);

  React.useEffect(() => {
    dispatch(
      getAllTransactions({
        limit: pageSize,
        caller: txCaller,
        page: currentPage,
        search: debouncedSearchString || undefined
      })
    );
  }, [txCaller, debouncedSearchString, pageSize, caller, currentPage]);

  return (
    <React.Fragment>
      <Box border="1px solid #e2e8f0" borderRadius={12} py={4}>
        <TableContainer>
          <Box m="16px" className="flex items-center gap-4">
            <InputGroup width="256px">
              <Input
                width="256px"
                fontSize="12px"
                paddingLeft="40px"
                borderRadius="10px"
                value={searchString}
                height="40px !important"
                border="1px solid #EDEBF9"
                placeholder={"Search by Id"}
                onChange={(e) => setSearchString(e.target.value)}
                _focus={{ border: "1px solid #EDEBF9 !important" }}
                _hover={{ border: "1px solid #EDEBF9 !important" }}
              />

              <InputLeftElement
                h="100%"
                mr="12px"
                children={<Icon as={SearchNormal} color="#A0A3BD" />}
              />
            </InputGroup>

            {!!allFilters.length && (
              <div className="w-full max-w-[200px]">
                <Select
                  height="40px"
                  onChange={(v) => setFilter(v?.value)}
                  value={{ label: txCaller, value: txCaller }}
                  options={allFilters.map((f) => ({ label: f, value: f }))}
                />
              </div>
            )}
          </Box>

          {transactions.isLoading && (
            <LoadingTable headers={headers} pageSize={10} />
          )}

          {!transactions?.data?.length && <NoData text="No Transactions" />}

          {!!transactions?.data?.length && !transactions?.isLoading && (
            <Table variant="simple" id={ChangeraTransactionType.WITHDRAWAL}>
              <Thead>
                <Tr>
                  {headers.map((el, idx) => (
                    <Th
                      key={idx}
                      textTransform="capitalize"
                      color="gray.900"
                      fontSize={"md"}
                      py="4"
                    >
                      {el}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {transactions?.data?.map((t) => {
                  return (
                    <Tr key={t._id}>
                      <Td lineHeight="20px">
                        <Center gap="2" justifyContent={"flex-start"}>
                          <Stack>
                            <Text
                              as={Link}
                              fontWeight={700}
                              color="gray.900"
                              lineHeight="20px"
                              _hover={{ color: "#B1B4BF" }}
                              textDecoration="none !important"
                              href={`/customers/${t.userId}`}
                            >
                              {t.userId}
                            </Text>
                          </Stack>
                        </Center>
                      </Td>
                      <Td
                        fontWeight={600}
                        color={getTransactionTypeColor(t.type)}
                      >
                        {getSymbolFromCurrency(t.currency ?? "")}{" "}
                        {formatter.format(t.amount) ?? "-"}
                      </Td>

                      <Td color="gray.600" className="capitalize">
                        {t.caller || "-"}
                      </Td>
                      <Td color="gray.600">{t.description || "-"}</Td>

                      <Td color="gray.500">
                        <Text>
                          {new Date(t.createdAt).toDateString()}&nbsp;
                        </Text>
                        <Text>
                          {new Date(t.createdAt).toLocaleTimeString()}
                        </Text>
                      </Td>
                      <Td>
                        <Button
                          rounded="lg"
                          size={"sm"}
                          variant="outline"
                          colorScheme={"primary"}
                          onClick={() => onOpenTransactionDetails(t as any)}
                        >
                          View More
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </TableContainer>
        <PaginationWrapper
          pages={pages}
          offset={offset}
          pageSize={pageSize}
          isDisabled={isDisabled}
          pagesCount={pagesCount}
          currentPage={currentPage}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          handlePageChange={handlePageChange}
          totalDataCount={100}
        />
      </Box>

      <TransactionDetailsModal />
    </React.Fragment>
  );
};

export default TransactionWithdrawal;
