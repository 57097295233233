import { AuditTrailFilter } from "data/models/audit-trail";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/audit.reducer";
import auditService from "data/services/audit.service";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";

export const setAuditTrailFilter = (data: Partial<AuditTrailFilter>) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setAuditTrailFilter({ data }));
  };
};

export const getAllAuditTrail = (params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllAuditTrailLoading());
      const res = await auditService.getAllAuditTrail(params);
      dispatch(
        actions.getAllAuditTrailSuccesss({
          data: res.data.data.list,
          pagination: res.data.data.pagination
        })
      );
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllAuditTrailFailed(msg));
    }
  };
};
