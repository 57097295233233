import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/settings.reducer";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import settingsService from "data/services/settings.service";
import { ChargesFilter, IUpdatePasswordBody } from "data/models/settings";

export const uploadProfileImage = (
  file: File,
  filename: string,
  callback: () => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.UserAvatarUploadBegin());
      const response = await settingsService.uploadFileS3(file, filename);

      const regex = /.com\/\//gi;
      let image = response.location;
      image = image.replaceAll(regex, ".com/");

      const res = await settingsService.uploadImage({ image });
      dispatch(actions.UserAvatarUploadSuccess());
      callback();
      toast.success(res.data.message);
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.UserAvatarUploadFailed(msg));
    }
  };
};

export const deleteProfileImage = (filePath: string, callback: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.UserAvatarUploadBegin());
      await settingsService.deleteFileS3(filePath);

      const res = await settingsService.deleteImage();
      dispatch(actions.UserAvatarUploadSuccess());
      callback();
      toast.success(res.data.message);
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.UserAvatarUploadFailed(msg));
    }
  };
};

export const updatePassword = async (
  body: IUpdatePasswordBody,
  callbackSuccess: () => void,
  callbackFailure: () => void
) => {
  try {
    await settingsService.updatePassword(body);
    callbackSuccess();
  } catch (err: any) {
    const axiosError = err as AxiosError<{ message: string }>;
    const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
    toast.error(msg);
    callbackFailure();
  }
};

export const getAuthorization = (params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAuthorizationLoading());
      const res = await settingsService.getAuthorizations(params);
      dispatch(
        actions.getAuthorizationSuccesss({
          data: res.data.data,
          pagination: res.data.meta
        })
      );
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAuthorizationFailed(msg));
    }
  };
};

export const getConfigs = (params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getConfigLoading());
      const res = await settingsService.getConfigs(params);
      dispatch(
        actions.getConfigSuccesss({
          data: res.data.data.data,
          pagination: res.data.data.meta
        })
      );
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getConfigFailed(msg));
    }
  };
};

export const setChargesFilter = (data: Partial<ChargesFilter>) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setChargesFilter({ data }));
  };
};

export const getAllCharges = (params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllChargeslLoading());
      const res = await settingsService.getAllCharges(params);
      dispatch(
        actions.getAllChargesSuccesss({
          data: res.data.data.data,
          pagination: res.data.data.meta
        })
      );
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllChargesFailed(msg));
    }
  };
};

export const getAllLimits = (params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllLimitslLoading());
      const res = await settingsService.getAllLimits(params);
      dispatch(
        actions.getAllLimitsSuccesss({
          data: res.data.data.data,
          pagination: res.data.data.meta
        })
      );
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllLimitsFailed(msg));
    }
  };
};

export const getAllRates = (params: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllRateslLoading());
      const res = await settingsService.getAllRates(params);
      dispatch(
        actions.getAllRatesSuccesss({
          data: res.data.data.data,
          pagination: res.data.data.meta
        })
      );
    } catch (err: any) {
      const axiosError = err as AxiosError<{ message: string }>;
      const msg = axiosError.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.getAllRatesFailed(msg));
    }
  };
};
