import {
  AuditTrailData,
  AuditTrailState,
  AuditTrailFilter
} from "data/models/audit-trail";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPaginationPayday } from "data/models/base";

const pagination = {
  nextPage: 0,
  total: 0,
  previousPage: 0,
  pageCount: 10,
  page: 1,
  limit: 10
};

const initialState: AuditTrailState = {
  filter: {},
  getAllAuditTrail: {
    data: [],
    error: "",
    pagination,
    loading: false
  }
};

const AuditTrailSlice = createSlice({
  initialState,
  name: "audit-trail",
  reducers: {
    getAllAuditTrailLoading: (state) => {
      state.getAllAuditTrail.error = "";
      state.getAllAuditTrail.loading = true;
    },

    getAllAuditTrailFailed: (state, action: PayloadAction<string>) => {
      state.getAllAuditTrail.loading = false;
      state.getAllAuditTrail.error = action.payload;
    },

    getAllAuditTrailSuccesss: (
      state,
      action: PayloadAction<{
        data: AuditTrailData[];
        pagination: IPaginationPayday;
      }>
    ) => {
      state.getAllAuditTrail.error = "";
      state.getAllAuditTrail.loading = false;
      state.getAllAuditTrail.data = action.payload.data;
      state.getAllAuditTrail.pagination = action.payload.pagination;
    },

    setAuditTrailFilter: (
      state,
      action: PayloadAction<{ data: Partial<AuditTrailFilter> }>
    ) => {
      state.filter = { ...action.payload.data };
    }
  }
});

export const actions = AuditTrailSlice.actions;

export default AuditTrailSlice.reducer;
