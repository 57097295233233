import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPagination } from "data/models/base";
import {
  ICryptoWalletResData,
  IFiatWalletResData,
  IWalletState
} from "data/models/wallets";

const pagination: IPagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: IWalletState = {
  allFiatWallets: {
    data: [],
    pagination,
    loading: false,
    error: ""
  },
  allCryptoWallets: {
    data: [],
    pagination,
    loading: false,
    error: ""
  }
};

const WalletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    getAllFiatWalletBegin: (state) => {
      state.allFiatWallets.loading = true;
      state.allFiatWallets.error = "";
    },
    getAllFiatWalletSuceess: (
      state,
      action: PayloadAction<{
        data: IFiatWalletResData[];
        pagination: IPagination;
      }>
    ) => {
      state.allFiatWallets.data = action.payload.data;
      state.allFiatWallets.pagination = action.payload.pagination;
      state.allFiatWallets.loading = false;
      state.allFiatWallets.error = "";
    },
    getAllFiatWalletFailed: (state, action: PayloadAction<string>) => {
      state.allFiatWallets.loading = false;
      state.allFiatWallets.error = action.payload;
    },
    getAllCryptoWalletBegin: (state) => {
      state.allCryptoWallets.loading = true;
      state.allCryptoWallets.error = "";
    },
    getAllCryptoWalletSuceess: (
      state,
      action: PayloadAction<{
        data: ICryptoWalletResData[];
        pagination: IPagination;
      }>
    ) => {
      state.allCryptoWallets.data = action.payload.data;
      state.allCryptoWallets.pagination = action.payload.pagination;
      state.allCryptoWallets.loading = false;
      state.allCryptoWallets.error = "";
    },
    getAllCryptoWalletFailed: (state, action: PayloadAction<string>) => {
      state.allCryptoWallets.loading = false;
      state.allCryptoWallets.error = action.payload;
    }
  }
});

export const actions = WalletSlice.actions;

export default WalletSlice.reducer;
