import {
  TeamManagementState,
  GetTeamMemberSuccessPayloadAction,
  GetAllTeamMembersSuccessPayloadAction,
  GetTeamMemberAuditLogsSuccessPayloadAction
} from "data/models/team-management";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const pagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: TeamManagementState = {
  allTeamMembers: {
    data: [],
    error: "",
    pagination,
    loading: false
  },

  teamMember: {
    error: "",
    pagination,
    data: null,
    loading: false,
    deactivateError: "",
    deactivateLoading: false
  },

  teamMemberAudit: {
    data: [],
    error: "",
    pagination,
    loading: false
  }
};

const TeamManagementSlice = createSlice({
  initialState,
  name: "team-management",
  reducers: {
    getAllTeamMembersLoading: (state) => {
      state.allTeamMembers.data = [];
      state.allTeamMembers.error = "";
      state.allTeamMembers.loading = true;
    },

    getTeamMemberAuditLogsLoading: (state) => {
      state.teamMemberAudit.data = [];
      state.teamMemberAudit.error = "";
      state.teamMemberAudit.loading = true;
    },

    getTeamMemberLoading: (state) => {
      state.teamMember.error = "";
      state.teamMember.data = null;
      state.teamMember.loading = true;
    },

    deactivateTeamMemberLoading: (state) => {
      state.teamMember.deactivateError = "";
      state.teamMember.deactivateLoading = true;
    },

    getAllTeamMembersFailed: (state, action: PayloadAction<string>) => {
      state.allTeamMembers.loading = false;
      state.allTeamMembers.error = action.payload;
    },

    getTeamMembersAuditLogsFailed: (state, action: PayloadAction<string>) => {
      state.teamMemberAudit.loading = false;
      state.teamMemberAudit.error = action.payload;
    },

    getTeamMemberFailed: (state, action: PayloadAction<string>) => {
      state.teamMember.loading = false;
      state.teamMember.error = action.payload;
    },

    deactivateTeamMemberFailed: (state, action: PayloadAction<string>) => {
      state.teamMember.deactivateLoading = false;
      state.teamMember.deactivateError = action.payload;
    },

    getAllTeamMembersSuccess: (
      state,
      action: PayloadAction<GetAllTeamMembersSuccessPayloadAction>
    ) => {
      state.allTeamMembers.error = "";
      state.allTeamMembers.loading = false;
      state.allTeamMembers.data = action.payload.data;
      state.allTeamMembers.pagination = <any>action.payload.pagination;
    },

    getTeamMemberAuditLogsSuccess: (
      state,
      action: PayloadAction<GetTeamMemberAuditLogsSuccessPayloadAction>
    ) => {
      state.teamMemberAudit.error = "";
      state.teamMemberAudit.loading = false;
      state.teamMemberAudit.data = action.payload.data;
      state.teamMemberAudit.pagination = <any>action.payload.pagination;
    },

    getTeamMemberSuccess: (
      state,
      action: PayloadAction<GetTeamMemberSuccessPayloadAction>
    ) => {
      state.teamMember.error = "";
      state.teamMember.loading = false;
      state.teamMember.data = action.payload.data;
      state.teamMember.pagination = <any>action.payload.pagination;
    },

    deactivateTeamMemberSuccess: (state, action: PayloadAction<any>) => {
      state.teamMember.deactivateError = "";
      state.teamMember.deactivateLoading = false;

      const index = state.allTeamMembers.data.findIndex(
        (t) => t.id === action.payload
      );

      state.allTeamMembers.data.splice(index, 1);
    }
  }
});

export const actions = TeamManagementSlice.actions;

export default TeamManagementSlice.reducer;
