import { useRoutes } from "react-router-dom";
import routes from "app/routes";
import { Suspense } from "react";
import { LoadingNoBg as Loading } from "views/components/loading";
// import WrongURL from "views/components/wrong-url";

const App = (): JSX.Element => {
  // var currentLocation = window.location;

  // if (
  //   currentLocation.origin ===
  //     "https://changera-business-admin-prod.web.app/" ||
  //   currentLocation.hostname === "changera-business-admin-prod.web.app" ||
  //   currentLocation.host === "changera-business-admin-prod.web.app"
  // ) {
  //   return <WrongURL />;
  // }

  const routesHere = useRoutes(routes);

  return <Suspense fallback={<Loading />}>{routesHere}</Suspense>;
};

export default App;
