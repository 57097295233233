import Content from "./content";
import Header from "./header";
import SideBar from "./sideBar";
import React from "react";
import { getUserProfile } from "data/store";
import { useAppDispatch } from "app/hooks";

const DashboardLayout = (): JSX.Element => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return (
    <div className="flex w-screen overflow-hidden">
      <SideBar />
      <div className="w-full grow max-h-screen overflow-x-hidden overflow-y-scroll">
        <Header />
        <Content />
      </div>
    </div>
  );
};

export default DashboardLayout;
