import Login from "./login";
import { TwoFACode } from "./login/two-fa-code";
import { Fragment, useState } from "react";

const LoginContainer = () => {
  const [show2FA, setShow2FA] = useState(false);
  const [values, setValues] = useState({ email: "", password: "" });
  const [use2FA, setUse2FA] = useState(false);
  const [loginCode, setLoginCode] = useState("");

  return (
    <Fragment>
      <div className="w-11/12 p-6 bg-white mdx:w-5/12 sm:w-9/12 shadow-xl border rounded-md">
        {show2FA ? (
          <TwoFACode
            email={values.email}
            use2FA={use2FA}
            loginCode={loginCode}
          />
        ) : (
          <Login
            values={values}
            setValues={setValues}
            setShow2FA={setShow2FA}
            setUse2FA={setUse2FA}
            setLoginCode={setLoginCode}
          />
        )}
      </div>
    </Fragment>
  );
};

export default LoginContainer;
