import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPagination } from "data/models/base";
import {
  // IGetAllAdminProfiles,
  IGetAllRolesResData,
  IGroupedRoleCountResData,
  IRolesState
} from "data/models/roles";

const pagination: IPagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: IRolesState = {
  allRoles: {
    data: null,
    loading: false,
    error: ""
  },
  groupedRoleCount: {
    data: [],
    loading: false,
    error: ""
  },
  allAdminProfiles: {
    data: [],
    pagination,
    loading: false,
    error: ""
  }
};

const RolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    getGroupedRoleCountBegin: (state) => {
      state.groupedRoleCount.loading = true;
      state.groupedRoleCount.error = "";
    },
    getGroupedRoleCountSuceess: (
      state,
      action: PayloadAction<{
        data: IGroupedRoleCountResData[];
      }>
    ) => {
      state.groupedRoleCount.data = action.payload.data;
      state.groupedRoleCount.loading = false;
      state.groupedRoleCount.error = "";
    },
    getGroupedRoleCountFailed: (state, action: PayloadAction<string>) => {
      state.groupedRoleCount.loading = false;
      state.groupedRoleCount.error = action.payload;
    },
    getRolesBegin: (state) => {
      state.allRoles.loading = true;
      state.allRoles.error = "";
    },
    getRolesSuceess: (
      state,
      action: PayloadAction<{
        data: IGetAllRolesResData;
      }>
    ) => {
      state.allRoles.data = action.payload.data;
      state.allRoles.loading = false;
      state.allRoles.error = "";
    },
    getRolesFailed: (state, action: PayloadAction<string>) => {
      state.allRoles.loading = false;
      state.allRoles.error = action.payload;
    },
    getAllAdminProfilesBegin: (state) => {
      state.allAdminProfiles.loading = true;
      state.allAdminProfiles.error = "";
    },
    getAllAdminProfilesSuceess: (state, action: any) => {
      state.allAdminProfiles.data = action.payload.data;
      state.allAdminProfiles.pagination.currentPage =
        action.payload.currentPage;
      state.allAdminProfiles.pagination.hasNext = action.payload.hasNext;
      state.allAdminProfiles.pagination.hasPrevious =
        action.payload.hasPrevious;
      state.allAdminProfiles.pagination.lastPage = action.payload.lastPage;
      state.allAdminProfiles.pagination.next = action.payload.next;
      state.allAdminProfiles.pagination.pageSize = action.payload.pageSize;
      state.allAdminProfiles.pagination.prevPage = action.payload.prevPage;
      state.allAdminProfiles.pagination.total = action.payload.total;
      state.allAdminProfiles.loading = false;
      state.allAdminProfiles.error = "";
    },
    getAllAdminProfilesFailed: (state, action: PayloadAction<string>) => {
      state.allAdminProfiles.loading = false;
      state.allAdminProfiles.error = action.payload;
    }
  }
});

export const actions = RolesSlice.actions;

export default RolesSlice.reducer;
