import {
  IBusinessMobileMoneyWalletParams,
  // IGetAllBusinessKycParams,
  IGetAllBusinessParams,
  IGetAllUsersParams
} from "data/models/user";
import {
  // getAllBusinessKyc,
  getAllBusinesses,
  getAllUsers,
  getBusinessBank,
  getBusinessMobileMoneyWallets,
  getSingleBusiness,
  getSingleBusinessTxSummary,
  searchAllUsers,
  getAllPaydayUsers
} from "data/store";
import { getSingleBusinessReset } from "data/store/reducers/user.reducer";
import { useAppDispatch, useAppSelector } from "./useReduxState";

export const useAllBusinesses = () => {
  const allBusiness = useAppSelector((state) => state.users.allBusiness);

  const dispatch = useAppDispatch();

  const dispatchAllBusinesses = async (params: IGetAllBusinessParams) => {
    dispatch(getAllBusinesses(params));
  };

  return { dispatchAllBusinesses, allBusiness };
};

export const useAllBusinessKyc = () => {
  const allBusinessKyc = useAppSelector((state) => state.users.businessKyc);

  const dispatch = useAppDispatch();

  const dispatchAllBusinessKyc = async (params: Record<string, any>) => {
    dispatch(getAllPaydayUsers(params));
  };

  const dispatchSearchUsers = async (params: Record<string, any>) => {
    dispatch(searchAllUsers(params));
  };

  return { dispatchAllBusinessKyc, allBusinessKyc, dispatchSearchUsers };
};

export const useAllUsers = () => {
  const allUsers = useAppSelector((state) => state.users.allUsers);

  const dispatch = useAppDispatch();

  const dispatchAllUsers = async (params: IGetAllUsersParams) => {
    dispatch(getAllUsers(params));
  };

  return { dispatchAllUsers, allUsers };
};

export const useSingleBusiness = () => {
  const singleBusiness = useAppSelector((state) => state.users.singleBusiness);

  const dispatch = useAppDispatch();

  const dispatchSingleBusiness = async (id: string) => {
    dispatch(getSingleBusiness(id));
  };

  const dispatchSingleBusinessReset = () => {
    dispatch(getSingleBusinessReset());
  };

  return {
    dispatchSingleBusiness,
    singleBusiness,
    dispatchSingleBusinessReset
  };
};

export const useSingleBusinessTxSummary = () => {
  const singleBusinessTxSummary = useAppSelector(
    (state) => state.users.singleBusinessTransactionSummary
  );

  const dispatch = useAppDispatch();

  const dispatchSingleBusinessTxSummary = async (
    id: number,
    params: { currency: string; from?: string; to?: string }
  ) => {
    dispatch(getSingleBusinessTxSummary(id, params));
  };

  return { dispatchSingleBusinessTxSummary, singleBusinessTxSummary };
};

export const useBusinessMobileMoneyWallets = () => {
  const businessMobileMoneyWallet = useAppSelector(
    (state) => state.users.singleBusinessMobileMoneyWallets
  );

  const dispatch = useAppDispatch();

  const dispatchBusinessMobileMoneyWallets = async (
    params: IBusinessMobileMoneyWalletParams
  ) => {
    dispatch(getBusinessMobileMoneyWallets(params));
  };

  return { dispatchBusinessMobileMoneyWallets, businessMobileMoneyWallet };
};

export const useBusinessBank = () => {
  const businessBank = useAppSelector(
    (state) => state.users.singleBusinessBank
  );

  const dispatch = useAppDispatch();

  const dispatchBusinessBank = async (
    params: IBusinessMobileMoneyWalletParams
  ) => {
    dispatch(getBusinessBank(params));
  };

  return { dispatchBusinessBank, businessBank };
};
