import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPagination } from "data/models/base";
import { NewPaginationState } from "data/models/transaction";
import {
  IBusinessBank,
  IBusinessMobileMoneyWallet,
  IBusinessRequestData,
  IBusinessVirtualAccount,
  IBusinessVirtualCard,
  ISingleBusinessRequestData,
  ISingleUserKyc,
  ITransactionSummary,
  IUsersRequestData,
  IUserState
} from "data/models/user";

const pagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const newpagination = {
  limit: 10,
  nextPage: 0,
  page: 1,
  pageCount: 0,
  previousPage: 0,
  total: 0
};

const initialState: IUserState = {
  allBusiness: {
    data: [],
    pagination,
    loading: false,
    error: ""
  },
  allUsers: {
    data: [],
    pagination,
    loading: false,
    error: ""
  },
  singleBusiness: {
    data: null,
    kyc: null,
    loading: false,
    error: ""
  },
  singleBusinessTransactionSummary: {
    data: null,
    loading: false,
    error: ""
  },
  singleBusinessMobileMoneyWallets: {
    data: [],
    pagination,
    loading: false,
    error: ""
  },
  singleBusinessBank: {
    data: [],
    pagination,
    loading: false,
    error: ""
  },
  BusinessDocsUpload: {
    loading: false,
    error: ""
  },
  updateBusinessVirtualCard: { error: "", loading: false },
  businessKyc: {
    data: [],
    error: "",
    loading: false,
    pagination: newpagination
  },
  businessVirtaulAccounts: {
    data: { accounts: [] },
    loading: false,
    error: ""
  },
  businessVirtualCards: {
    data: { cards: [] },
    loading: false,
    error: "",
    pagination
  },
  userStatuses: {
    data: [],
    error: "",
    loading: false
  }
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getAllBusinessBegin: (state) => {
      state.allBusiness.loading = true;
      state.allBusiness.error = "";
    },
    getUserStatusesBegin: (state) => {
      state.userStatuses.loading = true;
      state.userStatuses.error = "";
    },
    getUserStatusesFailed: (state, action: PayloadAction<string>) => {
      state.userStatuses.loading = false;
      state.userStatuses.error = action.payload;
    },
    getUserStatusesSuccess: (state, action: PayloadAction<string[]>) => {
      state.userStatuses.error = "";
      state.userStatuses.loading = false;
      state.userStatuses.data = action.payload;
    },
    getAllBusinessSuceess: (
      state,
      action: PayloadAction<{
        data: IBusinessRequestData[];
        pagination: IPagination;
      }>
    ) => {
      state.allBusiness.data = action.payload.data;
      state.allBusiness.pagination = action.payload.pagination;
      state.allBusiness.loading = false;
      state.allBusiness.error = "";
    },
    getAllBusinessFailed: (state, action: PayloadAction<string>) => {
      state.allBusiness.loading = false;
      state.allBusiness.error = action.payload;
    },
    getAllUsersBegin: (state) => {
      state.allUsers.loading = true;
      state.allUsers.error = "";
    },
    getAllUsersSuceess: (
      state,
      action: PayloadAction<{
        data: IUsersRequestData[];
        pagination: IPagination;
      }>
    ) => {
      state.allUsers.data = action.payload.data;
      state.allUsers.pagination = action.payload.pagination;
      state.allUsers.loading = false;
      state.allUsers.error = "";
    },
    getAllUsersFailed: (state, action: PayloadAction<string>) => {
      state.allUsers.loading = false;
      state.allUsers.error = action.payload;
    },
    getSingleBusinessBegin: (state) => {
      state.singleBusiness.loading = true;
      state.singleBusiness.error = "";
    },
    getSingleBusinessSuceess: (
      state,
      action: PayloadAction<{
        data: ISingleBusinessRequestData;
      }>
    ) => {
      state.singleBusiness.data = action.payload.data;
      state.singleBusiness.loading = false;
      state.singleBusiness.error = "";
    },
    getSingleUserKycSuceess: (
      state,
      action: PayloadAction<{
        data: ISingleUserKyc;
      }>
    ) => {
      state.singleBusiness.kyc = action.payload.data;
      state.singleBusiness.loading = false;
      state.singleBusiness.error = "";
    },
    getSingleBusinessFailed: (state, action: PayloadAction<string>) => {
      state.singleBusiness.loading = false;
      state.singleBusiness.error = action.payload;
    },
    getSingleBusinessReset: (state) => {
      state.singleBusiness.data = null;
      state.singleBusiness.loading = false;
      state.singleBusiness.error = "";
    },
    getSingleBusinessTxSummaryBegin: (state) => {
      state.singleBusinessTransactionSummary.loading = true;
      state.singleBusinessTransactionSummary.error = "";
    },
    getSingleBusinessTxSummarySuceess: (
      state,
      action: PayloadAction<{
        data: ITransactionSummary;
      }>
    ) => {
      state.singleBusinessTransactionSummary.data = action.payload.data;
      state.singleBusinessTransactionSummary.loading = false;
      state.singleBusinessTransactionSummary.error = "";
    },
    getSingleBusinessTxSummaryFailed: (
      state,
      action: PayloadAction<string>
    ) => {
      state.singleBusinessTransactionSummary.loading = false;
      state.singleBusinessTransactionSummary.error = action.payload;
    },
    getBusinessMobileMoneyWalletsBegin: (state) => {
      state.singleBusinessMobileMoneyWallets.loading = true;
      state.singleBusinessMobileMoneyWallets.error = "";
    },
    getBusinessMobileMoneyWalletsSuceess: (
      state,
      action: PayloadAction<{
        data: IBusinessMobileMoneyWallet[];
        pagination: IPagination;
      }>
    ) => {
      state.singleBusinessMobileMoneyWallets.data = action.payload.data;
      state.singleBusinessMobileMoneyWallets.pagination =
        action.payload.pagination;
      state.singleBusinessMobileMoneyWallets.loading = false;
      state.singleBusinessMobileMoneyWallets.error = "";
    },
    getBusinessMobileMoneyWalletsFailed: (
      state,
      action: PayloadAction<string>
    ) => {
      state.singleBusinessMobileMoneyWallets.loading = false;
      state.singleBusinessMobileMoneyWallets.error = action.payload;
    },
    getBusinessBankBegin: (state) => {
      state.singleBusinessBank.loading = true;
      state.singleBusinessBank.error = "";
    },
    getBusinessBankSuceess: (
      state,
      action: PayloadAction<{
        data: IBusinessBank[];
        pagination: IPagination;
      }>
    ) => {
      state.singleBusinessBank.data = action.payload.data;
      state.singleBusinessBank.pagination = action.payload.pagination;
      state.singleBusinessBank.loading = false;
      state.singleBusinessBank.error = "";
    },
    getBusinessBankFailed: (state, action: PayloadAction<string>) => {
      state.singleBusinessBank.loading = false;
      state.singleBusinessBank.error = action.payload;
    },
    BusinessDocsUploadBegin: (state) => {
      state.BusinessDocsUpload.loading = true;
    },
    BusinessDocsUploadSuccess: (state) => {
      state.BusinessDocsUpload.loading = false;
    },
    BusinessDocsUploadFailed: (state, action: PayloadAction<string>) => {
      state.BusinessDocsUpload.loading = false;
      state.BusinessDocsUpload.error = action.payload;
    },
    getBusinessVirtualAccountsBegin: (state) => {
      state.businessVirtaulAccounts.loading = true;
    },
    getBusinessVirtualAccountsFailed: (
      state,
      action: PayloadAction<string>
    ) => {
      state.businessVirtaulAccounts.loading = false;
      state.businessVirtaulAccounts.error = action.payload;
    },
    getBusinessVirtualAccountsSuccess: (
      state,
      action: PayloadAction<{ data: IBusinessVirtualAccount }>
    ) => {
      state.businessVirtaulAccounts.loading = false;
      state.businessVirtaulAccounts.data = action.payload.data;
    },
    getAllBusinessKycBegin: (state) => {
      state.businessKyc.error = "";
      state.businessKyc.loading = true;
    },
    getAllBusinessKycSuccess: (
      state,
      action: PayloadAction<{ users: any[]; pagination: NewPaginationState }>
    ) => {
      state.businessKyc.error = "";
      state.businessKyc.loading = false;
      state.businessKyc.data = action.payload.users;
      state.businessKyc.pagination = action.payload.pagination;
    },
    getAllBusinessKycFailed: (state, action: PayloadAction<string>) => {
      state.businessKyc.loading = false;
      state.businessKyc.error = action.payload;
    },
    getBusinessVirtualCardsBegin: (state) => {
      state.businessVirtualCards.loading = true;
    },
    getBusinessVirtualCardsFailed: (state, action: PayloadAction<string>) => {
      state.businessVirtualCards.loading = false;
      state.businessVirtualCards.error = action.payload;
    },
    getBusinessVirtualCardsSuccess: (
      state,
      action: PayloadAction<{
        data: IBusinessVirtualCard;
      }>
    ) => {
      state.businessVirtualCards.loading = false;
      state.businessVirtualCards.data = action.payload.data;
    },
    updateBusinessVirtualCardBegin: (state) => {
      state.updateBusinessVirtualCard.loading = true;
    },
    updateBusinessVirtualCardEnd: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.updateBusinessVirtualCard.loading = false;
      state.updateBusinessVirtualCard.error = action.payload || "";
    }
  }
});

export const actions = UserSlice.actions;

export const { getSingleBusinessReset } = actions;

export default UserSlice.reducer;
