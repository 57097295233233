import {
  Box,
  Heading,
  Icon,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import { UilBars } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import Logo from "app/assets/img/logo-dark-small.png";
import SideNav from "../sideBar/side-nav";
import { useLayoutEffect } from "react";
import { useAppSelector } from "app/hooks";

const Header = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useAppSelector((state) => state.dashboard?.userProfile);

  useLayoutEffect(() => {
    const closeMobileNavOnResize = () => {
      if (window.innerWidth > 768) {
        onClose();
      }
    };
    window.addEventListener("resize", closeMobileNavOnResize);

    return () => {
      window.removeEventListener("resize", closeMobileNavOnResize);
    };
  }, []);

  return (
    <>
      <header className="hidden h-20 sticky top-0 left-0 bg-white p-4 md:flex justify-end after:absolute after:content-[''] after:w-full after:h-[1px] after:bg-gray-200 after:top-full after:left-0 z-[1100]">
        <div className="flex items-center justify-between p-6 gap-4 ">
          <Box>
            <Heading
              fontSize="sm"
              maxWidth="16ch"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {user?.firstName || ""} {user?.lastName || ""}
            </Heading>
            <Text
              maxWidth="18ch"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="xs"
            >
              {user?.email || ""}
            </Text>
          </Box>
        </div>
      </header>
      <header className="md:hidden h-20 sticky top-0 left-0 bg-black text-white p-4 flex justify-between after:absolute after:content-[''] after:w-full after:h-[1px] after:bg-gray-200 after:top-full after:left-0 z-[1100]">
        <div className="h-full">
          <Link to="/">
            <img src={Logo} className="h-full py-2 invert" />
          </Link>
        </div>
        <div className="flex items-center justify-between py-6 gap-4 ">
          <Icon as={UilBars} w={8} h={10} cursor="pointer" onClick={onOpen} />
        </div>
      </header>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent className="p-0">
          <DrawerHeader className="bg-black" padding="0">
            <Box
              className="relative h-20"
              _after={{
                position: "absolute",
                width: "100%",
                height: "1px",
                background: "white",
                content: "''"
              }}
            >
              <div className="h-full">
                <Link to="/">
                  <img src={Logo} className="h-full px-6 py-6 invert" />
                </Link>
              </div>
            </Box>
            <DrawerCloseButton color="white" top="7" />
          </DrawerHeader>

          <DrawerBody className="bg-black tracking-wide" padding="0">
            <SideNav onCloseSideBar={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Header;
