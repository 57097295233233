import getSymbolFromCurrency from "currency-symbol-map";
import { supportedCountries } from "./supported-countries";

export const currencyFormatter = (currency: string) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.toUpperCase()
  });
};

export const numberToCurrencyFormatter = new Intl.NumberFormat("en-US", {
  notation: "standard",
  compactDisplay: "short",
  maximumFractionDigits: 2
});

export const currencyStringFormater = (currency: string) => {
  return currency === "cusd".toLocaleLowerCase()
    ? "cUSD"
    : currency.toUpperCase();
};

export const getCurrencySymbolFromCountry = (country: string) => {
  const selectedCountry = supportedCountries.find((c) => c.name === country);
  return getSymbolFromCurrency(selectedCountry?.currency || "");
};

export const resolveGenerateStatementCurrency = (
  currency: string = "",
  country: string = ""
) => {
  let value = currency ? currency : country;
  let func = currency ? getSymbolFromCurrency : getCurrencySymbolFromCountry;

  let symbol = value === "ghs" || value === "Ghana" ? "" : func(value || "");

  let name = symbol ? "" : currencyStringFormater(value || "");
  name = name === "GHANA" ? "GHS" : name;
  symbol = symbol || "";
  return { name, symbol };
};

export const getTransactionTypeColor = (type: string) => {
  if (type === "debit") return "red";
  if (type == "credit") return "green";
  return "black";
};

export const getNameFromCurrency = (currency: string) => {
  switch (currency) {
    case "ngn":
      return "Nigerian Naira";
    case "gbp":
      return "British Pounds";
    case "eur":
      return "European Euro";
    case "usd":
      return "US Dollar";
    case "ghs":
      return "Ghanian Cedis";
    case "kes":
      return "Kenyan Shillings";
    default:
      return "";
  }
};

export const fiatCurrencies = ["ngn", "gbp", "eur", "usd", "ghs", "kes"];
export const cryptoCurrencies = ["usdt", "usdc", "cusd"];
