import {
  Input,
  Button,
  InputGroup,
  InputLeftElement,
  InputRightElement
} from "@chakra-ui/react";

import toast from "react-hot-toast";
import { FC, useState } from "react";
import authService from "data/services/auth.service";
import { UilPadlock } from "@iconscout/react-unicons";
import { UilEnvelope } from "@iconscout/react-unicons";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";

const Login: FC<{
  setValues: (x: any) => any;
  setShow2FA: (x: any) => any;
  setUse2FA: (x: any) => any;
  setLoginCode: any;
  values: { email: string; password: string };
}> = ({ values, setValues, setShow2FA, setUse2FA, setLoginCode }) => {
  //
  const { email, password } = values;

  const handleChange =
    (name: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [name]: e.target.value });
    };

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await authService.loginAdminUser(values);
      setLoading(false);
      setShow2FA(true);
      setUse2FA(res.data.data.enable2FA);
      setLoginCode(res.data.data.code);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || DEFAULT_ERROR_MESSAGE);
      setLoading(false);
    }
  };

  return (
    <>
      <p className="text-gray-900 text-heading100 mdx:text-heading300">
        Sign In
      </p>
      <p className="mt-1 text-gray-700">
        Access the Payday console using your email and password.
      </p>
      <div className="mt-6 mdx:mt-10 authForm">
        <form onSubmit={handleSubmit}>
          <div className="my-4">
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilEnvelope color="#B1B4BF" />}
              />
              <Input
                focusBorderColor="none"
                type="email"
                placeholder="Business Email"
                size="lg"
                background={"transparent"}
                color="#444B59"
                value={email}
                onChange={handleChange("email")}
                name="email"
                isRequired
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
            </InputGroup>
          </div>
          <div className="my-4">
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilPadlock color="#B1B4BF" />}
              />
              <Input
                focusBorderColor="none"
                type={show ? "text" : "password"}
                placeholder="Password"
                size="lg"
                name="password"
                color="#667085"
                isRequired
                value={password}
                onChange={handleChange("password")}
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
              <InputRightElement width="4.5rem" top="auto">
                <Button size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </div>
          <div className="flex gap-4 mt-8 mdx:mt-12">
            <Button
              isDisabled={!values.email || !values.password}
              isLoading={loading}
              className="mdx:p-6 bg-black"
              textColor="white"
              type="submit"
              bgColor="#000"
              w="full"
              _hover={{ bgColor: "#000" }}
            >
              <div className="flex items-center">
                <p>Sign In</p>
              </div>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
