import { AxiosResponse } from "axios";
import instance, { instancePayday } from "data/axios-setup";
import { IPagination } from "data/models/base";
import {
  ICryptoWalletResData,
  IFiatWalletResData,
  IGetCryptoWalletParams,
  IGetFiatWalletParams,
  IProccessCryptoTx,
  IProccessFiatTx
} from "data/models/wallets";

class WalletService {
  async getAllFiatWallet(
    params: IGetFiatWalletParams
  ): Promise<
    AxiosResponse<{ data: IFiatWalletResData[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/wallet/fiat", { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllCryptoWallet(
    params: IGetCryptoWalletParams
  ): Promise<
    AxiosResponse<{ data: ICryptoWalletResData[]; pagination: IPagination }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/wallet/crypto", { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllCallers(): Promise<AxiosResponse<{ data: string[] }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get("/available/callers");
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async fundFiatWallet(
    id: string,
    body: IProccessFiatTx
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/wallet/fiat/fund/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async lockWallet(
    id: string,
    body: any
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(`/users/${id}/wallet/lock`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async refundWallet(body: any): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(`/refund/request`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async transferWallet(body: any): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(
          `/internal-transfer-console`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async withdrawCard(
    id: string,
    body: any
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(
          `/users/${id}/virtual-cards/withdraw`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async enableCard(
    id: string,
    uniqueId: string,
    body: any
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(
          `/users/${id}/virtual-cards/${uniqueId}/toggle/block`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async terminateCard(
    id: string,
    uniqueId: string,
    body: any
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(
          `/users/${id}/virtual-cards/${uniqueId}/terminate`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async reComputeWallet(
    body: any
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(
          `/recompute-wallet-balance`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async debitWallet(
    id: string,
    body: any
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(
          `/users/${id}/wallet/debit`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async swapWallet(body: any): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.post(`/users/wallet/convert`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async fundCryptoWallet(
    id: string,
    body: IProccessCryptoTx
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/wallet/crypto/fund/${id}`, body);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async withdrawFromFiatWallet(
    id: string,
    body: IProccessFiatTx
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(
          `/admin/wallet/fiat/withdraw/${id}`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async withdrawFromCryptoWallet(
    id: string,
    body: IProccessCryptoTx
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(
          `/admin/wallet/crypto/withdraw/${id}`,
          body
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new WalletService();
