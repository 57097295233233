import LocalStorage from "./local-storage";

export const tokenKey = "paydayAdminToken";

class ExpirySession {
  static get = (key: string) => {
    let stringValue: string = LocalStorage.get(key); // get details about token.
    if (!!stringValue) {
      let value = JSON.parse(stringValue);

      this.set(key, value.value);
      return value.value;

      // let expirationDate = new Date(value.expirationDate);
      // if (expirationDate > new Date()) {
      //   this.set(key, value.value);
      //   return value.value;
      // } else {
      //   LocalStorage.remove(key); // remove token if expired.
      // }
    }
    return null;
  };

  static set = (
    key: string,
    value: any,
    expirationInSeconds: number = 1800
  ) => {
    let expirationDate = new Date(
      new Date().getTime() + 1000 * expirationInSeconds
    ); // create new expiring date.
    let newValue = {
      value,
      expirationDate: expirationDate.toISOString()
    };
    LocalStorage.set(key, JSON.stringify(newValue)); // add token to local storage.
  };

  static clear = () => {
    LocalStorage.remove(tokenKey);
  };
}

export default ExpirySession;
