import {
  GenerateTransactionStatementBody,
  ICardTransaction,
  ITransaction,
  ITransactionSummary,
  IWithdrawalTransaction,
  PaginationState
} from "data/models/transaction";
import instance, { instancePayday } from "data/axios-setup";
import { AxiosResponse } from "axios";
import qs from "qs";
import { getEnv } from "data/config";
import {
  IMeta,
  IRefundData,
  IThirdPartyDepositData,
  IWithdrawalsData
} from "data/models/withdrawals";

class TransactionService {
  async getAllTransactions(
    params?: any
  ): Promise<AxiosResponse<{ data: ITransaction[]; meta: IMeta }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instancePayday.get(`/transactions`, { params });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getUsersTransactions(
    userId: string,
    params?: any
  ): Promise<
    AxiosResponse<{
      data: {
        user: any;
        transactions: { list: ITransaction[]; pagination: IMeta };
      };
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instancePayday.get(
          `/users/${userId}/transactions`,
          { params }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getTransactions(
    params: any
  ): Promise<
    AxiosResponse<{ data: ITransaction[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get(`/transactions`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "comma" });
          }
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getTransactionCallers(): Promise<AxiosResponse<{ data: string[] }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instancePayday.get("/available/callers");
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAirtimeDataTransactions(
    params: any
  ): Promise<
    AxiosResponse<{ data: ITransaction[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`admin/transaction/airtime`, { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getConversionTransactions(
    params: any
  ): Promise<
    AxiosResponse<{ data: ITransaction[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`admin/transaction/conversion`, {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllWithdrawals(
    params?: any
  ): Promise<AxiosResponse<{ data: any[]; pagination: PaginationState }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(`admin/withdrawals`, {
          params
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAllWithdrawalsPayDay(
    params?: any
  ): Promise<AxiosResponse<{ data: IWithdrawalsData[]; meta: IMeta }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instancePayday.get(`/withdrawals`, {
          params
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async processAWithdrawalPayDay(
    withdrawalId: string,
    body?: {
      status: string;
      reason: string;
    }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instancePayday.patch(
          `/user/withdrawals/${withdrawalId}`,
          body
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getWithdrawalTransaction(
    id: number,
    params?: {
      type: "withdrawal" | "pay-money";
    }
  ): Promise<AxiosResponse<{ data: IWithdrawalTransaction }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instance.get(
          `admin/withdrawals/transaction/${id}`,
          {
            params
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getCardTransactions(
    params: any
  ): Promise<
    AxiosResponse<{ data: ICardTransaction[]; pagination: PaginationState }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`admin/transaction/virtual-cards`, {
          params
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getTransactionSummaryV2(
    id: string | number,
    params: { currency: string; from?: string; to?: string }
  ): Promise<AxiosResponse<{ data: ITransactionSummary }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get(`/admin/transaction/summary/${id}`, {
          params: { active: true, ...params },
          baseURL: `${getEnv("REACT_APP_BASE_URL")}/v2`
        });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async generateTransactionStatementV2(
    body: GenerateTransactionStatementBody,
    businessId: number
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.post(
          `admin/account-statement/${businessId} `,
          body
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAllThirdPartyDepositsPayDay(
    params?: any
  ): Promise<
    AxiosResponse<{ data: { data: IThirdPartyDepositData[]; meta: IMeta } }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instancePayday.get(`/third-party-deposits`, {
          params
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async processAThirdPartyDepositsPayDay(
    withdrawalId: string,
    body?: {
      status: string;
      reason: string;
    }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instancePayday.patch(
          `/third-party-deposits/${withdrawalId}`,
          body
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAllRefundsPayday(
    params?: any
  ): Promise<AxiosResponse<{ data: { data: IRefundData[]; meta: IMeta } }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instancePayday.get(`/refund/request`, {
          params
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async processARefundsPayday(
    withdrawalId: string,
    body?: {
      status: string;
      reason: string;
    }
  ): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await instancePayday.post(
          `/refund/request/${withdrawalId}/${body?.status}`
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new TransactionService();
