export const getPaySomeoneSendMode = (mode: string) => {
  switch (mode) {
    case "momo":
      return "mobile-money-wallet";
    case "bank-account":
      return "bank-account";
    case "changera-business":
      return "business-to-business";
    case "changera-wallet":
      return "business-to-personal";
    default:
      return "-";
  }
};

export const getWithdrawalTransactionStatus = (status: any) => {
  switch (status) {
    case "success":
      return "approved";
    case "pending":
      return "pending";
    case "failed":
      return "denied";
    default:
      return undefined;
  }
};

/** PAYDAY */
export const groupTransactionCallers = (callers: string[]) => {
  const result: string[] = [];
  const filters: { [x: string]: string[] } = {
    admin: [],
    "virtual-card": [],
    "virtual-account": []
  };

  callers.forEach((caller) => {
    if (caller.startsWith("admin-")) {
      filters.admin.push(caller);
      return result.push("admin");
    }
    if (caller.startsWith("virtual-card-")) {
      filters?.["virtual-card"]?.push(caller);
      return result.push("virtual-card");
    }
    if (caller.startsWith("virtual-account-")) {
      filters?.["virtual-account"].push(caller);
      return result.push("virtual-account");
    }

    result.push(caller);
  });

  return { callers: Array.from(new Set(result)), filters };
};
