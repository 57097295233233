import {
  Bank,
  CardPos,
  Element4,
  People,
  Setting2,
  Stickynote,
  TaskSquare,
  LockCircle,
  Money,
  MoneyChange,
  ReceiptEdit,
  MoneyRecive,
  MoneySend
} from "iconsax-react";
import SideBarLink, {
  SideBarLinkHome
} from "views/components/link/sideBarLink";
import { CloseSideBar } from "./models";
import { UilSignout } from "@iconscout/react-unicons";
import { useLogout } from "app/hooks";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Stack
} from "@chakra-ui/react";

const SideNav = ({ onCloseSideBar }: CloseSideBar): JSX.Element => {
  const closeSideNav = () => {
    if (!onCloseSideBar) return;
    onCloseSideBar();
  };

  const { logoutHandler } = useLogout();

  return (
    <>
      <Box
        className="overflow-y-scroll"
        sx={{
          "::-webkit-scrollbar": {
            display: "none"
          }
        }}
      >
        <nav className="py-8">
          <SideBarLinkHome to="/" onClick={closeSideNav}>
            <Icon as={Element4} w={6} h={6} />
            <span>Dashboard</span>
          </SideBarLinkHome>
          <SideBarLink to="/customers" onClick={closeSideNav}>
            <Icon as={People} w={6} h={6} />
            <span>Customers</span>
          </SideBarLink>
          <SideBarLink to="/transactions" onClick={closeSideNav}>
            <Icon as={CardPos} w={6} h={6} />
            <span>Transactions</span>
          </SideBarLink>
          <SideBarLink to="/withdrawals" onClick={closeSideNav}>
            <Icon as={Bank} w={6} h={6} />
            <span>Withdrawals</span>
          </SideBarLink>
          <SideBarLink to="/third-party-deposits" onClick={closeSideNav}>
            <Icon as={MoneyRecive} w={6} h={6} />
            <span>Deposits</span>
          </SideBarLink>
          <SideBarLink to="/refunds" onClick={closeSideNav}>
            <Icon as={MoneySend} w={6} h={6} />
            <span>Refunds</span>
          </SideBarLink>
          <SideBarLink to="/audit-trail" onClick={closeSideNav}>
            <Icon as={Stickynote} w={6} h={6} />
            <span>Audit Trail</span>
          </SideBarLink>
          <Accordion allowToggle>
            <AccordionItem border={"none"}>
              <h2>
                <AccordionButton p="0">
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    color={"gray.400"}
                    position="relative"
                    px="6"
                    py="4"
                    _hover={{
                      _after: {
                        position: "absolute",
                        top: "0",
                        left: "calc(100% - 5px)",
                        width: "5px",
                        height: "100%",
                        content: "''",
                        background: "white"
                      },
                      color: "white",
                      backgroundColor: "#62687E"
                    }}
                  >
                    <Icon as={Setting2} w={6} h={6} mr="4" />
                    <span>Settings</span>
                  </Box>
                </AccordionButton>
              </h2>
              <AccordionPanel as={Stack} pb={4} color={"white"}>
                <SideBarLink to="/authorization" onClick={closeSideNav}>
                  <Icon as={LockCircle} w={6} h={6} />
                  <span>Authorization</span>
                </SideBarLink>
                <SideBarLink to="/configs" onClick={closeSideNav}>
                  <Icon as={TaskSquare} w={6} h={6} />
                  <span>Configs</span>
                </SideBarLink>
                <SideBarLink to="/charges" onClick={closeSideNav}>
                  <Icon as={Money} w={6} h={6} />
                  <span>Charges</span>
                </SideBarLink>
                <SideBarLink to="/limits" onClick={closeSideNav}>
                  <Icon as={ReceiptEdit} w={6} h={6} />
                  <span>Limits</span>
                </SideBarLink>
                <SideBarLink to="/rates" onClick={closeSideNav}>
                  <Icon as={MoneyChange} w={6} h={6} />
                  <span>Rates</span>
                </SideBarLink>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </nav>
      </Box>
      <Flex
        color="red.500"
        _hover={{ color: "red.600" }}
        alignItems="center"
        gap="4"
        className="px-6 py-4 pb-16 mt-auto cursor-pointer"
        onClick={logoutHandler}
      >
        <Icon as={UilSignout} w={6} h={6} />
        <span>Logout</span>
      </Flex>
    </>
  );
};

export default SideNav;
