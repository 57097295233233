export function getEnv(variable: string, options?: { optional: boolean }) {
  if (process.env[variable] === undefined) {
    if (options?.optional) {
      console.warn(
        `[@env]: Environmental variable for ${variable} is not supplied. \n So a default value will be generated for you.`
      );
    } else {
      throw new Error(
        `You must create an environment variable for ${variable}`
      );
    }
  }

  return process.env[variable];
}

interface AppConfig {
  server: string;
  pdServer: string;
  pdAuthServer: string;
}

const config: AppConfig = {
  server: `${getEnv("REACT_APP_BASE_URL")}/v1`,
  pdServer: `${getEnv("REACT_APP_BASE_URL_PD")}/api/admin/v1`,
  pdAuthServer: `${getEnv("REACT_APP_BASE_URL_PD_AUTH")}/api`
};
for (const [key, value] of Object.entries(config)) {
  if (!value) {
    console.log(
      `[-] Environmental varialbe ${key} is not set. Application might not funtion properly.`
    );
  }
}
export default config;
