import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { IPagination } from "data/models/base";
import {
  IGetAllAdminProfiles,
  IGetAllAdminProfilesParams,
  IGetAllRolesResData,
  IGroupedRoleCountResData
} from "data/models/roles";

class RoleService {
  async getGroupedRoleCount(): Promise<
    AxiosResponse<{ data: IGroupedRoleCountResData[] }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/role/summary");
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllRoles(): Promise<AxiosResponse<{ data: IGetAllRolesResData }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/roles");
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getAllAdminProfiles(params: IGetAllAdminProfilesParams): Promise<
    AxiosResponse<{
      data: IGetAllAdminProfiles[];
      pagination: IPagination;
    }>
  > {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.get("/admin/account", { params });
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }

  async assignRole(data: {
    email: string;
    role: string;
  }): Promise<AxiosResponse<{ message: string }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await instance.put(`/admin/assign-role`, data);
        resolve(res);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new RoleService();
