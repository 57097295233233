import { logOut } from "data/store";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "./useReduxState";

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logOut(navigate));
  };

  return { logoutHandler };
};
