import { Box, Heading, Text, Button, Center } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function Forbidden() {
  return (
    <Center h="calc(100vh - 5rem)">
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, primary.400, primary.600)"
          backgroundClip="text"
        >
          403
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Permission Denied
        </Text>
        <Text color={"gray.500"} mb={6}>
          The page you're looking for is restricted from you
        </Text>

        <Link to="/">
          <Button
            colorScheme="primary"
            bgGradient="linear(to-r, primary.400, primary.500, primary.600)"
            color="white"
            variant="solid"
          >
            Go to Home
          </Button>
        </Link>
      </Box>
    </Center>
  );
}
